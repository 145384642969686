import Button from 'react-bootstrap/Button';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import GetAddress from '../GetAddress';
import BuyAndSell1 from './BuyAndSell1';
import BuyAndSell2 from './BuyAndSell2';
import BuyAndSell3 from './BuyAndSell3';
import BuyAndSell4 from './BuyAndSell4';
import BuyAndSell5 from './BuyAndSell5';
import BuyAndSell6 from './BuyAndSell6';
import BuyAndSell7 from './BuyAndSell7';
import PhoneVerify from '../PhoneVerify';
import BuyRealEstate1 from '../BuyRealEstate/BuyRealEstate1';
import BuyRealEstate2 from '../BuyRealEstate/BuyRealEstate2';
import BuyRealEstate3 from '../BuyRealEstate/BuyRealEstate3';
import BuyRealEstate4 from '../BuyRealEstate/BuyRealEstate4';
import BuyRealEstate5 from '../BuyRealEstate/BuyRealEstate5';
import BuyRealEstate6 from '../BuyRealEstate/BuyRealEstate6';
import BuyRealEstate7 from '../BuyRealEstate/BuyRealEstate7';
import BuyRealEstate8 from '../BuyRealEstate/BuyRealEstate8';
import SellRealEstate1 from '../SellRealEstate/SellRealEstate1';
import SellRealEstate2 from '../SellRealEstate/SellRealEstate2';
import SellRealEstate3 from '../SellRealEstate/SellRealEstate3';
import SellRealEstate4 from '../SellRealEstate/SellRealEstate4';
import SellRealEstate5 from '../SellRealEstate/SellRealEstate5';
import SellRealEstate6 from '../SellRealEstate/SellRealEstate6';
import SellRealEstate7 from '../SellRealEstate/SellRealEstate7';
import BuyerGetArea from '../BuyerGetArea';
import SellerGetAddress from '../SellerGetAddress';
import CompleteForm from '../CompleteForm';

export default function BuyAndSellSteps({ setProgress, originalId, setCurrentStepName, setUserAnswers, navigate, location, setProgressColor, setProgressAnimated, setProgressStriped }) {

    const currentStep = originalId - 200 + 1;
    setCurrentStepName("Buy & sell with us and get rewarded");
    const buyAndSell = true;

    switch (currentStep) {
        case 1:
            setProgress(5);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <BuyerGetArea setUserAnswers={setUserAnswers} navigate={navigate} location={location} />;
        case 2:
            setProgress(10);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <BuyRealEstate1 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />;
        case 3:
            setProgress(20);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <BuyRealEstate2 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />;
        case 4:
            setProgress(30);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <BuyRealEstate3 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />;
        case 5:
            setProgress(50);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <BuyRealEstate5 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />;
        case 6:
            setProgress(60);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <BuyRealEstate6 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />;
        case 7:
            setProgress(70);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <BuyRealEstate7 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />;
        case 8:
            setProgress(75);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <BuyRealEstate8 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />;
        case 9:
            setProgress(80);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <SellerGetAddress setUserAnswers={setUserAnswers} navigate={navigate} location={location} />;
        case 10:
            setProgress(85);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <SellRealEstate1 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />;
        case 11:
            setProgress(90);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <SellRealEstate2 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />;
        case 12:
            setProgress(92);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <SellRealEstate3 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />;
        case 13:
            setProgress(94);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <SellRealEstate4 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />;
        case 14:
            setProgress(96);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <SellRealEstate5 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />;

        case 15:
            setProgress(100);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <SellRealEstate7 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />;
        case 16:
            setProgress(100);
            setProgressColor("success");
            setProgressAnimated(false);
            return <CompleteForm section="buyAndSell" setUserAnswers={setUserAnswers} navigate={navigate} location={location} />;
    }

    return <GetAddress />;
}