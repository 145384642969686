import React, { useState, useRef } from "react";
import { Form } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { Check2Circle } from 'react-bootstrap-icons';
import Alert from 'react-bootstrap/Alert';
import { postData } from "../functions";
import { APIENDPOINTURL, COMPANYFRIENDLYPHONE } from "../settings";
import CallButton from "../components/CallButton";
import * as settings from "../settings";
import * as functions from "../functions";

export default function CompleteForm({ setUserAnswers, location, section='' }) {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [rawDigits, setRawDigits] = useState("");
  const inputRef = useRef(null);
  const [enterAccountView, setEnterAccountView] = useState(true);
  const [enterVerificationView, setEnterVerificationView] = useState(false);

  const [error, setError] = useState('');

  // Format digits into (xxx) xxx-xxxx
  const formatPhoneNumber = (digits) => {
    if (!digits.length) return "";
    let part1 = digits.slice(0, 3);
    let part2 = digits.slice(3, 6);
    let part3 = digits.slice(6, 10);

    let result = "(" + part1;
    if (digits.length > 3) {
      result += ") " + part2;
    }
    if (digits.length > 6) {
      result += "-" + part3;
    }
    return result;
  };

  // Handle phone input changes
  const handleChange = (e) => {
    const inputValue = e.target.value;
    const selectionStart = e.target.selectionStart;
    const digitsBeforeCaret = (inputValue.slice(0, selectionStart).match(/\d/g) || []).length;

    const digits = inputValue.replace(/\D/g, "").slice(0, 10);
    setRawDigits(digits);

    // Re-position the caret
    requestAnimationFrame(() => {
      const formatted = formatPhoneNumber(digits);
      if (!inputRef.current) return;

      let newCaretPos = 0;
      let count = 0;
      while (newCaretPos < formatted.length && count < digitsBeforeCaret) {
        if (/\d/.test(formatted[newCaretPos])) {
          count++;
        }
        newCaretPos++;
      }
      inputRef.current.setSelectionRange(newCaretPos, newCaretPos);
    });
  };

  const formattedValue = formatPhoneNumber(rawDigits);

  // Validate Full Name for two words
  const isValidFullName = (name) => {
    const trimmed = name.trim();
    return trimmed && trimmed.split(/\s+/).length >= 2;
  };

  // Basic email validation
  const isValidEmail = (value) => {
    return /\S+@\S+\.\S+/.test(value);
  };

  // Called when user clicks “Create Account”
  const submitCreateAccount = () => {
    // Validate Full Name
    if (!isValidFullName(fullName)) {
      setError("Please enter your full name (at least two words).");
      return;
    }

    // Validate Email
    if (!isValidEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    // Validate Phone
    if (rawDigits.length !== 10) {
      setError("Please enter a valid 10-digit phone number.");
      return;
    }

    if(section == "buy"){
      functions.triggerFunnelBuyConversion();
    }

    if(section == "sell"){
      functions.triggerFunnelSellConversion();
    }

    if(section == "buyAndSell"){
      functions.triggerFunnelBuyAndSellConversion();
    }

    // Combine data
    let allData = [];
    setUserAnswers((prevValue) => {
      allData = [...prevValue];
      return prevValue;
    });

    // Post data to the back-end
    postData(`${APIENDPOINTURL}?verify-phone`, {
      data: allData,
      phone: rawDigits,
      fullName,
      email
    });

    // Switch from account info view to the “confirmation” view
    setEnterAccountView(false);
    setEnterVerificationView(true);
    setError('');
  };

  return (
    <div>
      <h2>
        <Check2Circle /> You're all set!
      </h2>

      {/* === Account Creation / Info Collection View === */}
      {enterAccountView && (
        <>
          <p>
            Provide your info to see how much you’ll save at our 1% listing rate, gain exclusive property
            alerts, and connect with a licensed agent—no pressure or obligation.
          </p>

          {error && <Alert variant="danger">{error}</Alert>}

          <Form.Control
            style={{ marginBottom: "10px" }}
            size="lg"
            type="text"
            placeholder="Full Name (e.g., John Smith)"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />

          <Form.Control
            style={{ marginBottom: "10px" }}
            size="lg"
            type="email"
            placeholder="Email Address (e.g., john@example.com)"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Form.Control
            ref={inputRef}
            size="lg"
            type="text"
            placeholder="(847) 505-0101"
            value={formattedValue}
            onChange={handleChange}
          />

          <Button
            style={{ width: "70%", marginTop: "15px" }}
            variant="outline-primary"
            onClick={submitCreateAccount}
          >
            Create Account
          </Button>
        </>
      )}

      {/* === Confirmation View (instead of verification code) === */}
      {enterVerificationView && (
        <>
          {error && <Alert variant="danger">{error}</Alert>}

          <p>
            Your info has been received. A representative will be reaching out shortly!
          </p>
        </>
      )}

      <div style={{ margin: "15px" }}>
        <div>Need Help? Call Now For Local 24/7 Assistance</div>
        <CallButton />
      </div>

      {enterAccountView && (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <p style={{ width: "70%", textAlign: "center" }} className="small ">
            <i className="small">
              By providing your phone number, you consent to receiving calls, text messages, and emails
              from Matthew Kontos Agents, even if you are on a Do Not Call registry. To opt out at any time, reply “STOP” to any text message, or call us at {settings.COMPANYFRIENDLYPHONE}. Your information will never be sold or provided to third parties.
            </i>
          </p>
        </div>
      )}
    </div>
  );
}