import Button from 'react-bootstrap/Button';
import { formButtonStyle } from '../../styles';
import { handleOptionSelect } from '../../functions';

export default function SellRealEstate1({ setUserAnswers, navigate, location }) {
    return (
        <>
            <h2>What type of property are you planning to sell?</h2>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "PropertyTypeSellQuestion", "Detached house")
                }
            >
                Detached house
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "PropertyTypeSellQuestion", "Apartment or condo")
                }
            >
                Apartment or condo
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "PropertyTypeSellQuestion", "Row house")
                }
            >
                Row house
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "PropertyTypeSellQuestion", "Manufactured home")
                }
            >
                Manufactured home
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "PropertyTypeSellQuestion", "Empty lot or land")
                }
            >
                Empty lot or land
            </Button>
        </>
    );
}