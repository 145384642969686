import Button from 'react-bootstrap/Button';
import { formButtonStyle } from '../../styles';
import { handleOptionSelect } from '../../functions';

export default function SellRealestate3({ setUserAnswers, navigate, location }) {
    return (
        <>
            <h2>What is your preferred timeline for selling your property?</h2>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "SellTimelineQuestion", "Immediately")
                }
            >
                Immediately
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "SellTimelineQuestion", "1–2 months")
                }
            >
                1–2 months
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "SellTimelineQuestion", "3–5 months")
                }
            >
                3–5 months
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "SellTimelineQuestion", "6–9 months")
                }
            >
                6–9 months
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "SellTimelineQuestion", "Over 9 months")
                }
            >
                Over 9 months
            </Button>
        </>
    );
}