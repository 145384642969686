import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SearchIcon from '@mui/icons-material/Search';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CampaignIcon from '@mui/icons-material/Campaign';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import * as settings from "../settings";
import Tooltip from '@mui/material/Tooltip';
import * as functions from "../functions";

export default function HowItWorksSection() {
    return (
        <div
            style={{
                //display: 'flex',
                //justifyContent: 'center',
                //alignItems: 'center',

            }}
            className='bg-white'
            id="about"

        >
            <Container>
                <Row>
                    <Col sm={12} className='mt-5'>
                        <div className='text-center'>
                            <h5 className='text-secondary '><b>WHAT WE DO</b></h5>

                        </div>

                    </Col>
                    <Col sm={12}>
                        <div className='text-center'>

                            <h1 class><b>Full service real estate done right</b></h1>
                        </div>

                    </Col>


                    <Col sm={12} lg={3} >
                        <div className="d-flex justify-content-center">
                            <Card className='shadow border-0 mt-5 p-3' style={{ width: '22rem', height: "500px" }}>
                                <Card.Body>
                                    <div className="d-flex justify-content-center mb-4">
                                        <div
                                            className="rounded-circle bg-primary d-flex justify-content-center align-items-center shadow-sm"
                                            style={{
                                                width: '60px',
                                                height: '60px',
                                                transition: 'transform 0.6s',
                                                transform: 'rotateY(0deg)',
                                            }}
                                            onMouseEnter={(e) => e.currentTarget.style.transform = 'rotateY(360deg)'}
                                            onMouseLeave={(e) => e.currentTarget.style.transform = 'rotateY(0deg)'}
                                        >
                                            <SearchIcon className="text-white" style={{ fontSize: '36px', fontWeight: 'bold', }} />

                                        </div>
                                    </div>
                                    <Card.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                        Find your dream property
                                    </Card.Title>
                                    <Card.Text className="mt-4" style={{ fontSize: '1rem', color: '#666' }}>
                                        Get a head start on other buyers with ultra-responsive 24/7 buyers agents.                                    </Card.Text>
                                    <Card.Text className="mt-4s" style={{ fontSize: '1rem', color: '#666' }}>
                                        Receive property alerts, view private MLS listings, get same day showings, and access property disclosure reports not available to the general public.
                                    </Card.Text>

                                </Card.Body>
                                <Card.Footer style={{ backgroundColor: 'transparent', height: '60px' }}>
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                                        <a href="#" onClick={() => (window.location.href = `${process.env.PUBLIC_URL}/step/100`)} className="text-primary text-decoration-none">
                                            <b>See how it works {">"}</b>
                                        </a>
                                    </div>
                                </Card.Footer>
                            </Card>
                        </div>
                    </Col>
                    <Col sm={12} lg={3} >
                        <div className="d-flex justify-content-center">
                            <Card className='shadow border-0 mt-5 p-3' style={{ width: '22rem', height: "500px" }}>
                                <Card.Body>
                                    <div className="d-flex justify-content-center mb-4">
                                        <div
                                            className="rounded-circle bg-primary d-flex justify-content-center align-items-center shadow-sm"
                                            style={{
                                                width: '60px',
                                                height: '60px',
                                                transition: 'transform 0.6s',
                                                transform: 'rotateY(0deg)',
                                            }}
                                            onMouseEnter={(e) => e.currentTarget.style.transform = 'rotateY(360deg)'}
                                            onMouseLeave={(e) => e.currentTarget.style.transform = 'rotateY(0deg)'}
                                        >
                                            <AttachMoneyIcon className="text-white" style={{ fontSize: '36px', fontWeight: 'bold', }} />
                                        </div>
                                    </div>
                                    <Card.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                        Sell your home for a 1% listing fee
                                    </Card.Title>
                                    <Card.Text className="mt-4 " style={{ fontSize: '1rem', color: '#666', marginBottom: '1.5rem' }}>
                                        We provide all traditional real estate services for a lower price than competitors.
                                    </Card.Text>
                                    <Card.Text className="mb-5" style={{ fontSize: '1rem', color: '#666', marginBottom: '1.5rem' }}>
                                        Our service includes photography, property showings, open houses, and much more!                                    </Card.Text>

                                </Card.Body>
                                <Card.Footer style={{ backgroundColor: 'transparent', height: '60px' }}>
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                                        <a href="#" onClick={() => (window.location.href = `${process.env.PUBLIC_URL}/step/150`)} className="text-primary text-decoration-none">
                                            <b>Learn more about selling {">"}</b>
                                        </a>
                                    </div>
                                </Card.Footer>
                            </Card>
                        </div>
                    </Col>
                    <Col sm={12} lg={3} >
                        <div className="d-flex justify-content-center">
                            <Card className='shadow border-0 mt-5 p-3' style={{ width: '22rem', height: "500px" }}>
                                <Card.Body>
                                    <div className="d-flex justify-content-center mb-4 " >
                                        <div
                                            className="rounded-circle bg-primary d-flex justify-content-center align-items-center shadow-sm"
                                            style={{
                                                width: '60px',
                                                height: '60px',
                                                transition: 'transform 0.6s',
                                                transform: 'rotateY(0deg)',
                                            }}
                                            onMouseEnter={(e) => e.currentTarget.style.transform = 'rotateY(360deg)'}
                                            onMouseLeave={(e) => e.currentTarget.style.transform = 'rotateY(0deg)'}
                                        >
                                            <PublishedWithChangesIcon className="text-white" style={{ fontSize: '36px', fontWeight: 'bold', }} />
                                        </div>
                                    </div>
                                    <Card.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                        Buy & sell with us and get rewarded
                                    </Card.Title>
                                    <Card.Text className="mt-4" style={{ fontSize: '1rem', color: '#666', marginBottom: '1.5rem' }}>
                                        We can help you in all stages of your transactions.                                    </Card.Text>
                                    <Card.Text className="mt-4" style={{ fontSize: '1rem', color: '#666', marginBottom: '1.5rem' }}>
                                        To help you move, you get a $600 gift card of your choosing to several major retailers when you buy and sell at the same time.<Tooltip title="Combined sale and purchase value must exceed $300,000 to qualify.">**</Tooltip>
                                    </Card.Text>


                                </Card.Body>
                                <Card.Footer style={{ backgroundColor: 'transparent', height: '60px' }}>
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                                        <a href="#" onClick={() => (window.location.href = `${process.env.PUBLIC_URL}/step/200`)} className="text-primary text-decoration-none">
                                            <b>See your options {">"}</b>
                                        </a>
                                    </div>
                                </Card.Footer>
                            </Card>
                        </div>
                    </Col>
                    <Col sm={12} lg={3} >
                        <div className="d-flex justify-content-center">
                            <Card className='shadow border-0 mt-5 p-3' style={{ width: '22rem', height: "500px" }}>
                                <Card.Body>
                                    <div className="d-flex justify-content-center mb-4">
                                        <div
                                            className="rounded-circle bg-primary d-flex justify-content-center align-items-center shadow-sm"
                                            style={{
                                                width: '60px',
                                                height: '60px',
                                                transition: 'transform 0.6s',
                                                transform: 'rotateY(0deg)',
                                            }}
                                            onMouseEnter={(e) => e.currentTarget.style.transform = 'rotateY(360deg)'}
                                            onMouseLeave={(e) => e.currentTarget.style.transform = 'rotateY(0deg)'}
                                        >
                                            <ThumbUpOffAltIcon className="text-white" style={{ fontSize: '36px', fontWeight: 'bold', }} />
                                        </div>
                                    </div>
                                    <Card.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                        Receive quality and value
                                    </Card.Title>
                                    <Card.Text className="" style={{ fontSize: '1rem', color: '#666', marginBottom: '1.5rem' }}>
                                        We're proud to provide excellent service in all facets of residential real estate, at a fraction of the price of our competitors.
                                    </Card.Text>
                                    <Card.Text className="" style={{ fontSize: '1rem', color: '#666', marginBottom: '1.5rem' }}>
                                        Schedule a no-pressure consultation to learn what sets Matthew Kontos Agents apart.
                                    </Card.Text>

                                </Card.Body>
                                <Card.Footer style={{ backgroundColor: 'transparent', height: '60px' }}>
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                                        <a href="#" onClick={() => {functions.triggerPhoneConversion();window.location.href = `tel:${settings.COMPANYTELPHONE}`}} 
                                            className="text-primary text-decoration-none"
                                            style={{ textDecoration: 'none' }}>
                                            <b>Speak with an agent {">"}</b>
                                        </a>
                                    </div>
                                </Card.Footer>
                            </Card>
                        </div>
                    </Col>

                </Row>



            </Container>




        </div>
    );
}
