import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BuyerComparisonTable from './BuyerComparisonTable';
import GoogleMapAddressInput from './GoogleMapsAddressInput';

export default function BuyersSection({ isMobile }) {
  return (
    <div className='bg-light'>
      <Container>
        <Row>
          <Col sm={12} className='mt-5'>
            <div className='text-center'>
              <h5 className='text-secondary'><b>CONFIDENCE WITH EVERY PURCHASE</b></h5>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className='text-center'>
              <h1><b>Responsive and personalized buying experience</b></h1>
              <p>The market can be competitive! We are here to help get the home you want at the best price.</p>
              <img
                src={`${process.env.PUBLIC_URL}/svg/buyHouse.svg`}
                alt="Market Analysis"
                style={{
                  width: '150px',
                  height: '150px',
                  objectFit: 'contain',
                  filter: 'drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1))'
                }}
              />
              <h4>We're here to help you make informed decisions and beat out other offers.</h4>
              <h4></h4>
            </div>
          </Col>

          <Col md={3}>
          </Col>
          <Col xs={12} md={6}>
            <div style={{ marginBottom: "10px" }} >
              <BuyerComparisonTable /></div>
            <h4 className='mt-5 mb-3'>
              Have questions about a specific property? Ask us about it!
            </h4>
            <div className='mb-5'>
            <GoogleMapAddressInput />
</div>
          </Col>
          <Col md={3}>
          </Col>



        </Row>
      </Container>
    </div>
  );
}