import Button from 'react-bootstrap/Button';
import { formButtonStyle } from '../../styles';
import { handleOptionSelect } from '../../functions';

export default function BuyRealEstate3({ setUserAnswers, navigate, location }) {
    return (
        <>
            <h2>What stage are you at in securing financing for your home?</h2>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "FinancingStageQuestion", "Exploring my options")
                }
            >
                Exploring my options
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "FinancingStageQuestion", "Consulted with a lender")
                }
            >
                Consulted with a lender
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "FinancingStageQuestion", "Have pre-approval")
                }
            >
                Have pre-approval
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "FinancingStageQuestion", "Paying in cash, no mortgage needed")
                }
            >
                Paying in cash, no mortgage needed
            </Button>
        </>
    );
}