import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';

export default function TermsOfService() {
  return (
    <Container className="my-5">
      <Row>
        <Col>
          <h1 className="text-center">Loading Terms of Service...</h1>
          <p className="text-muted text-center">
            Last Updated: January 1, 2025
          </p>
          <hr />
        </Col>
      </Row>


    </Container>
  );
}