import React, { useState } from 'react';
import { Box, Slider } from '@mui/material';

const SavingsSlider = () => {
  const [price, setPrice] = useState(300000);

  const handleSliderChange = (event, newValue) => {
    setPrice(newValue);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    }).format(value);
  };

  // Calculate 3% for typical brokerage
  const agentFee3 = price * 0.03;

  // Calculate 1%, but enforce a $1,500 minimum
  const rawOurFee1 = price * 0.01; 
  const ourFee1 = rawOurFee1 < 1500 ? 1500 : rawOurFee1;

  // Savings = difference between 3% and our (possibly minimum) 1%
  const savings = agentFee3 - ourFee1;

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <h2 className="text-center mb-3">Compare savings vs others</h2>

      <h2 className="text-success text-center mb-4">
        You Save: {formatCurrency(savings)}
      </h2>
      <h6>
        Property Price: <b>{formatCurrency(price)}</b>
      </h6>
      <Slider
        value={price}
        onChange={handleSliderChange}
        min={55000}
        max={1500000}
        step={5000}
      />
      <p>
        Matthew Kontos Agents (<span className="text-success">1%</span>): <b className="text-success">{formatCurrency(ourFee1)}</b>
        {rawOurFee1 < 1500 && (
          <span className="text-danger d-block">
            (Note: Our minimum commission is $1,500)
          </span>
        )}
      </p>
      <p>
        Other Agencies (<span className="text-danger">3%</span>): <b className="text-danger">{formatCurrency(agentFee3)}</b>
      </p>
    </Box>
  );
};

export default SavingsSlider;