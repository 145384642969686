import React from "react";
import { Table } from "react-bootstrap";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from '@mui/material/Tooltip';

function BuyerComparisonTable() {
  return (
    <div className="table-responsive">
      <Table striped bordered className="table-border border-light">
        <thead className="border-light">
          <tr>
            <th></th>
            <th>
              <h5>Our Service</h5>
            </th>
            <th>
              <h5>Other Agencies</h5>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Access To Private Listings</th>
            <td style={{ textAlign: "center" }}>
              <CheckIcon className="text-primary" />
            </td>
            <td style={{ textAlign: "center" }}>
              <CloseIcon className="text-danger" />
            </td>
          </tr>
          <tr>
            <th>Fast Offer Creation</th>
            <td style={{ textAlign: "center" }}>
              <CheckIcon className="text-primary" />
            </td>
            <td style={{ textAlign: "center" }}>
              <CheckIcon className="text-primary" />
            </td>
          </tr>
          <tr>
            <th>Same Day Showings<Tooltip title="Available when showing requests are approved by the selling entity.">^</Tooltip></th>
            <td style={{ textAlign: "center" }}>
              <CheckIcon className="text-primary" />
            </td>
            <td style={{ textAlign: "center" }}>
              <CloseIcon className="text-danger" />
            </td>
          </tr>
          <tr>
            <th>Access Property Disclosures</th>
            <td style={{ textAlign: "center" }}>
              <CheckIcon className="text-primary" />
            </td>
            <td style={{ textAlign: "center" }}>
              <CheckIcon className="text-primary" />
            </td>
          </tr>
          <tr>
            <th>Fast 24/7 Availability</th>
            <td style={{ textAlign: "center" }}>
              <CheckIcon className="text-primary" />
            </td>
            <td style={{ textAlign: "center" }}>
              <CloseIcon className="text-danger" />
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default BuyerComparisonTable;