import React from 'react';
import { Button, Container } from 'react-bootstrap';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import * as settings from "../settings";
import * as functions from "../functions";

const LowerHeroSection = ({ navigate }) => {
  return (
    <div
      style={{
        backgroundImage: `
            linear-gradient(
              to right,
              rgba(0, 0, 0, 0.8),
              rgba(0, 0, 0, 0.6),
              rgba(0, 0, 0, 0.6),
              rgba(0, 0, 0, 0)
            ),
            url(${settings.SITEURL}/hero-bg.jpg)
          `,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
        padding: '30px',
      }}
    >
      <Container className="text-center">
        <h1>Select an option to learn more</h1>
        <p>Our automated forms can help answer your questions and get you started.</p>
        <div className="d-flex justify-content-center flex-wrap gap-3">
          <Button
            variant="outline-light"
            style={{
              borderRadius: '10px',
              fontSize: '1rem',
              fontWeight: 'bold',
              margin: '5px',
            }}
            onClick={() => (window.location.href = `${process.env.PUBLIC_URL}/step/100`)}
          >
            I'm buying
          </Button>
          <Button
            variant="outline-light"
            style={{
              borderRadius: '10px',
              fontSize: '1rem',
              fontWeight: 'bold',
              margin: '5px',
            }}
            onClick={() => (window.location.href = `${process.env.PUBLIC_URL}/step/150`)}
          >
            I'm selling
          </Button>
          <Button
            variant="outline-light"
            style={{
              borderRadius: '10px',
              fontSize: '1rem',
              fontWeight: 'bold',
              margin: '5px',
            }}
            onClick={() => (window.location.href = `${process.env.PUBLIC_URL}/step/200`)}
          >
            I'd like to buy and sell
          </Button>
        </div>
        <p className="mt-3">
          <a onClick={() => functions.triggerPhoneConversion()} href={"tel:" + settings.COMPANYTELPHONE} style={{ color: '#ffffff', textDecoration: 'underline' }}>
            <LocalPhoneIcon /> Speak With An Agent
          </a>
        </p>
      </Container>
    </div>
  );
};

export default LowerHeroSection;