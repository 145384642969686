import React, { useEffect, useRef, useState } from "react";
import { Form, FormControl, Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { handleOptionSelect } from "../functions";

export default function GetAddress({setUserAnswers, navigate, location }) {
  const mapRef = useRef(null);          // Reference to the map container
  const autocompleteRef = useRef(null); // Reference to the autocomplete input
  const markerRef = useRef(null);       // We'll store a single marker reference

  const [map, setMap] = useState(null);

  // Keep track of two separate things:
  // 1. userLocation (from "Use My Location")
  // 2. selectedLocation (from typed address)
  //
  // But the actual location we want to center on is finalLocation,
  // which always reflects whichever was chosen last.
  const [userLocation, setUserLocation] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  // finalLocation is what actually gets shown on the map & marker
  const [finalLocation, setFinalLocation] = useState(null);

  useEffect(() => {
    const loadGoogleMapsAPI = async () => {
      if (!window.google) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCCpHgwvCBKH38lAukfVdOdbO4bNWzgBns&libraries=places`;
        script.async = true;
        script.onload = initializeMap;
        document.body.appendChild(script);
      } else {
        initializeMap();
      }
    };

    loadGoogleMapsAPI();
  }, []);

  const initializeMap = () => {
    // Initialize Google Map with default location (Chicago example)
    const mapInstance = new window.google.maps.Map(mapRef.current, {
      center: { lat: 41.8781, lng: -87.6298 },
      zoom: 12,
    });
    setMap(mapInstance);

    // Initialize Autocomplete
    const autocomplete = new window.google.maps.places.Autocomplete(
      autocompleteRef.current,
      {
        types: ["establishment", "geocode"], // Include businesses and other establishments
        componentRestrictions: { country: "us" }, // Restrict to US (optional)
      }
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        setSelectedLocation(place);
        // Whichever is chosen last should take precedence:
        // so we update finalLocation to the newly typed place
        setFinalLocation({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      }
    });
  };

  // Whenever finalLocation changes, center the map & move/create the marker
  useEffect(() => {
    if (map && finalLocation) {
      map.setCenter(finalLocation);

      // If marker doesn't exist yet, create it
      if (!markerRef.current) {
        markerRef.current = new window.google.maps.Marker({
          position: finalLocation,
          map: map,
        });
      } else {
        // If marker already exists, just update its position
        markerRef.current.setPosition(finalLocation);
      }
    }
  }, [map, finalLocation]);

  const handleUseMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const userCoords = { lat: latitude, lng: longitude };
          setUserLocation(userCoords);

          // If "Use My Location" is clicked, it should override the typed address
          setFinalLocation(userCoords);

          // Clear out the autocomplete input
          if (autocompleteRef.current) {
            autocompleteRef.current.value = "";
          }
        },
        (error) => {
          console.error("Error getting user location:", error);
          alert("Unable to retrieve your location.");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // You have a finalLocation in state that you can use for your next step
    console.log("Submitted location:", finalLocation);
  };

  return (
    <div>
      <h1>Where is the service needed?</h1>
      <p>
        This is <b>only for pricing and estimated times of arrival</b>, you are{" "}
        <b>not ordering the service.</b>
      </p>
      <p>
        You may also enter a <b>city</b>, <b>business name</b>, or use{" "}
        <b>your location</b> if you don&apos;t know the address.
      </p>

      <Form onSubmit={handleFormSubmit} className="mb-3 d-flex align-items-center">
        <Container>
          <Row>
            <Col xs={12}>
              <div>
                <FormControl
                  type="text"
                  placeholder={
                    userLocation
                      ? `Lat: ${userLocation.lat} Long: ${userLocation.lng}`
                      : "Tempo Cafe, Chicago IL"
                  }
                  ref={autocompleteRef}
                  className="me-2"
                  style={{ width: "100%" }}
                  onFocus={() => {
                    const inputPosition =
                      autocompleteRef.current.getBoundingClientRect();
                    window.scrollTo({
                      top: window.scrollY + inputPosition.top - 10,
                      behavior: "smooth",
                    });
                  }}
                />
              </div>
            </Col>

            <div
              style={{
                width: "10%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              <Button
                style={{ marginTop: "10px", width: "200px" }}
                variant="secondary"
                onClick={handleUseMyLocation}
              >
                Use My Location
              </Button>
              {finalLocation && (
                <Button
                  style={{ marginTop: "10px", width: "200px" }}
                  variant="primary"
                  type="submit"
                  onClick={() => handleOptionSelect(navigate, location, setUserAnswers, "MapSubmission", {userLocation: userLocation, selectedLocation: selectedLocation, finalLocation: finalLocation})}
                >
                  Next
                </Button>
              )}
            </div>
          </Row>
        </Container>
      </Form>
      <div
        ref={mapRef}
        style={{ width: "100%", height: "300px", border: "1px solid #ddd" }}
      ></div>

      {selectedLocation && (
        <div className="mt-3">
          <h5>Selected Location:</h5>
          <p>{selectedLocation.name || selectedLocation.formatted_address}</p>
        </div>
      )}
    </div>
  );
}