import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Step from './components/Step';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Button from 'react-bootstrap/Button';
import LandingPage from './pages/LandingPage';
import StarRating from './components/StarRating';
import Card from 'react-bootstrap/Card';
import CallButton from './components/CallButton';
import CallText from './components/CallText';
import './App.css';
import FloatingNavbar from './components/FloatingNavbar';
import Footer from './components/Footer';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import * as functions from "./functions";



// Hook to detect the device type
const useIsMobile = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

  React.useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

function App() {
  const [steps, setSteps] = useState(null);
  const isMobile = useIsMobile();
  const itsThatOneUserWithChangingGclid = functions.itsThatOneUserWithChangingGclid();

  useEffect(() => {
    // Fetch the JSON file
    fetch(`${process.env.PUBLIC_URL}/data.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch the JSON file');
        }
        return response.json();
      })
      .then((jsonData) => {
        setSteps(jsonData);
      })
      .catch((error) => {
        console.error('Error fetching JSON:', error);
      });
      if(itsThatOneUserWithChangingGclid){
        functions.test()
      }
      
  }, []);


  return (
    <>
      {itsThatOneUserWithChangingGclid ? (
        <h1 style={{
          fontFamily: "'Press Start 2P', cursive",
          fontSize: '3rem',
          color: 'red',
          textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)',
          animation: 'shake 0.5s infinite alternate'
        }}>
          <i>WHO ARE YOU AND WHAT ARE YOU DOING HERE?</i>
        </h1>
      ) :
        <Router

        //comment out for dev
        //basename="/c"
        >

          <Routes>
            <Route path="/" element={<><FloatingNavbar /><LandingPage /></>} />
            <Route path="/*" element={<>Not Found</>} />

            <Route path="/step/:id" element={<Step isMobile={isMobile} />} />



            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />


          </Routes>


          <Footer />
        </Router>


      }
    </>
  );
}

export default App;