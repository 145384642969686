import Button from 'react-bootstrap/Button';
import { formButtonStyle } from '../../styles';
import { handleOptionSelect } from '../../functions';

export default function BuyRealEstate2({ setUserAnswers, navigate, location }) {
    return (
        <>
            <h2>What is your estimated budget for buying a property?</h2>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "BudgetQuestion", "$200K or below")
                }
            >
                $200K or below
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "BudgetQuestion", "$200K–$240K")
                }
            >
                $200K–$240K
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "BudgetQuestion", "$240K–$280K")
                }
            >
                $240K–$280K
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "BudgetQuestion", "$280K–$330K")
                }
            >
                $280K–$330K
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "BudgetQuestion", "$330K or above")
                }
            >
                $330K or above
            </Button>
        </>
    );
}