import React from 'react';
import * as settings from "../settings";
import * as functions from "../functions";

const Footer = () => {
  return (
    <footer className="bg-light  py-4">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h5>About Us</h5>
            <p>
              Matthew Kontos Agents is a dedicated real estate service that believes in offering complete,
              personalized support at a fraction of the usual cost. By combining modern
              technology with a network of vetted professionals, we’re able to provide
              24/7 buyer assistance, professional photography, property showings,
              open houses, and more—all at a low 1% listing fee. Our mission is to help
              you save money, find or sell your home quickly, and enjoy a truly seamless
              real estate experience.
            </p>
          </div>
          <div className="col-md-4">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="#" className="">Home</a></li>
              <li><a href={`${settings.SITEURL}/step/100`} className="">Buy</a></li>
              <li><a href={`${settings.SITEURL}/step/150`} className="">Sell</a></li>
              <li><a href={`${settings.SITEURL}/step/200`} className="">Buy & Sell</a></li>
              <li><a href="#faq" className="">Frequently Asked</a></li>
              {/* Added Terms of Service link here */}
              <li><a href="/terms" className="">Terms of Service</a></li>
              <li><a href="/privacy" className="">Privacy Policy</a></li>
            </ul>
          </div>
          <div className="col-md-4">
            <h5>Contact Us</h5>
            <p>
              Phone:           <a onClick={() => functions.triggerPhoneConversion()} href={"tel:" + settings.COMPANYTELPHONE} style={{ textDecoration: 'underline' }}>
              {settings.COMPANYFRIENDLYPHONE}</a>
            </p>
          </div>
        </div>
        <div className="text-center mt-4">
          <p><small>There is a 1% listing fee with a $1500 minimum.</small></p>
          <p><small>* Based on a listing commission of 1% vs other commissions at 3% for a $325,000 sale.</small></p>
          <p><small>** Combined sale and purchase value must exceed $300,000 to qualify.</small></p>
          <p><small>^ Available when showing requests are approved by the selling entity.</small></p>
          <p><small>Matthew Kontos is a licensed Real Estate Broker. Illinois Licensed Real Estate Broker 475.179050.</small></p>
          <p>&copy; {new Date().getFullYear()} Matthew Kontos Agents.<div>All Rights Reserved.</div></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;