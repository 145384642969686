import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import * as settings from '../settings';
import * as functions from "../functions";

export default function BottomGeneralCard({navigate}) {
    return (
        <div
            style={{
                //height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            id="contact"
        >
            <Card style={{ width: '22rem', padding: '1.5rem', borderRadius: '20px' }}>
                <Card.Body>
                    <div className="d-flex justify-content-center mb-4">
                        <div
                            className="rounded-circle bg-primary d-flex justify-content-center align-items-center shadow-sm"
                            style={{
                                width: '60px',
                                height: '60px',
                                transition: 'transform 0.6s',
                                transform: 'rotateY(0deg)',
                            }}
                            onMouseEnter={(e) => e.currentTarget.style.transform = 'rotateY(360deg)'}
                            onMouseLeave={(e) => e.currentTarget.style.transform = 'rotateY(0deg)'}
                        >
                            <QuestionMarkIcon className="text-white" style={{ fontSize: '36px', fontWeight: 'bold' }} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mb-3 mt-3">
                <Card.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                        How can we help you?
                    </Card.Title>
                    </div>
                    <Card.Text style={{ fontSize: '1rem', color: '#666', marginBottom: '1.5rem' }}>
                        Answer a few quick questions to see how we can help you.
                    </Card.Text>
                    <div className="d-grid gap-2">
                        <Button
                            variant="outline-primary"
                            style={{
                                borderRadius: '10px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                margin: "5px"
                            }}
                            onClick={() => (window.location.href = `${process.env.PUBLIC_URL}/step/100`)}
                        >
                            I’m buying
                        </Button>
                        <Button
                            variant="outline-primary"
                            style={{
                                borderRadius: '10px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                margin: "5px"
                                
                            }}
                            onClick={() => (window.location.href = `${process.env.PUBLIC_URL}/step/150`)}
                        >
                            I’m selling
                        </Button>
                        <Button
                            variant="outline-primary"
                            style={{
                                borderRadius: '10px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                margin: "5px"
                            }}
                            onClick={() => (window.location.href = `${process.env.PUBLIC_URL}/step/200`)}
                        >
                            I’m buying & selling
                        </Button>
                    </div>

                </Card.Body>
                <Card.Footer style={{ backgroundColor: 'transparent',}}>

                <div className="d-flex justify-content-center mb-3 mt-3">
                <Card.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                        Speak with us directly
                    </Card.Title>
                    </div>

                <div className="d-flex justify-content-center mb-3 mt-3">
                    
                    
                        <div
                            className="rounded-circle bg-primary d-flex justify-content-center align-items-center shadow-sm"
                            style={{
                                width: '60px',
                                height: '60px',
                                transition: 'transform 0.6s',
                                transform: 'rotateY(0deg)',
                            }}
                            onMouseEnter={(e) => e.currentTarget.style.transform = 'rotateY(360deg)'}
                            onMouseLeave={(e) => e.currentTarget.style.transform = 'rotateY(0deg)'}
                        >
                            <SupportAgentIcon className="text-white" style={{ fontSize: '36px', fontWeight: 'bold' }} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center mt-2" style={{ height: '100%' }}>
                        
                        <a onClick={() => functions.triggerPhoneConversion()} href={"tel:" + settings.COMPANYTELPHONE} className="text-muted text-decoration-none">
                            <b>Call us at {settings.COMPANYFRIENDLYPHONE}</b>
                        </a>
                    </div>
                </Card.Footer>
            </Card>
        </div>
    );
}