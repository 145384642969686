import Button from 'react-bootstrap/Button';
import { formButtonStyle } from '../../styles';
import { handleOptionSelect } from '../../functions';

export default function SellRealEstate6({ setUserAnswers, navigate, location }) {
    return (
        <>
            <h2>Are you planning to purchase a new property as well?</h2>
            <p>We offer solutions to simplify the process of buying and selling simultaneously.</p>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "BuyAndSellQuestion", "Yes")
                }
            >
                Yes
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "BuyAndSellQuestion", "No")
                }
            >
                No
            </Button>
        </>
    );
}