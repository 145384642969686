import React, { useState } from "react";
import * as settings from "../settings";
import * as functions from "../functions";

const FloatingNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <div className="container-fluid">
        {/* Logo */}
        <a className="navbar-brand" href="#">
          <img
            src="/logo.png" alt="Logo"
            style={{ height: "30px" }}
          />
        </a>

        {/* Hamburger menu */}
        <button
          className="navbar-toggler"
          type="button"
          aria-controls="navbarNav"
          aria-expanded={isOpen}
          aria-label="Toggle navigation"
          onClick={handleToggle}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar links */}
        <div
          className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
          id="navbarNav"
        >
          <ul className="navbar-nav me-auto mt-2">
            <li className="nav-item">
              <a className="nav-link" href="#" onClick={handleClose}>
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about" onClick={handleClose}>
                About
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={`${settings.SITEURL}/step/100`} onClick={handleClose}>
                Buy
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={`${settings.SITEURL}/step/150`} onClick={handleClose}>
                Sell
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={`${settings.SITEURL}/step/200`} onClick={handleClose}>
                Buy & Sell
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="#faq" onClick={handleClose}>
                Frequently Asked
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={`tel:${settings.COMPANYTELPHONE}`}onClick={() => { functions.triggerPhoneConversion(); handleClose() }}>
                Call Us
              </a>
            </li>
          </ul>
        </div>

        {/* Phone number for desktop */}
        <div className="d-none d-lg-block">
          <a
            href={"tel:" + settings.COMPANYTELPHONE}
            className="navbar-text"
            onClick={() => functions.triggerPhoneConversion()}
          >
            {settings.COMPANYFRIENDLYPHONE}
          </a>
        </div>
      </div>
    </nav>
  );
};

export default FloatingNavbar;