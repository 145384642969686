import React from "react";

const StatsBar = () => {
  return (
    <div className="bg-primary text-white text-center py-4">
      <div style={{textShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)"}} className="container d-flex justify-content-around">
        <div >
          <h3 className="fw-bold display-6">1%</h3>
          <p className="fw-bold">Listing fee</p>
        </div>
        <div>
          <h3 className="fw-bold display-6">$6,500</h3>
          <p className="fw-bold">Typical Seller Savings*</p>
        </div>
        <div>
          <h3 className="fw-bold display-6">24/7</h3>
          <p className="fw-bold">Agent Availability</p>
        </div>
      </div>
    </div>
  );
};

export default StatsBar;