import Button from 'react-bootstrap/Button';
import { formButtonStyle } from '../../styles';
import { useState } from 'react';
import { handleOptionSelect } from '../../functions';

export default function BuyRealEstate1({ setUserAnswers, navigate, location }) {
    return (
        <>
            <h2>What kind of property are you interested in purchasing?</h2>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "PropertyTypeQuestion", "Standalone house")
                }
            >
                Standalone house
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "PropertyTypeQuestion", "Condo unit")
                }
            >
                Condo unit
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "PropertyTypeQuestion", "Townhome")
                }
            >
                Townhome
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "PropertyTypeQuestion", "Manufactured home")
                }
            >
                Manufactured home
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "PropertyTypeQuestion", "Vacant land")
                }
            >
                Vacant land
            </Button>
        </>
    );
}