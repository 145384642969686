import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Chart } from 'react-charts'; // Be sure to npm install react-charts
import SavingsSlider from './SavingsSlider';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import * as settings from "../settings";
import * as functions from "../functions";

export default function TalkToAgentSection({ isMobile }) {

    return (
        <>
            {isMobile ? <div className='bg-white'>
                <Container>

                    {/* Content Section */}
                    <Row className='mt-4'>
                        <Col>
                            <Card style={{
                                borderRadius: '20px', backgroundImage: `
                                    linear-gradient(
                                    to right,
                                    rgba(0, 0, 0, 0.8),
                                    rgba(0, 0, 0, 0.6),
                                    rgba(0, 0, 0, 0.6),
                                    rgba(0, 0, 0, 0)
                                    ),
url(${settings.SITEURL}/interior.jpg)                                `,
                                color: 'white',
                            }} className='shadow border-0'>
                                <Card.Body>
                                    <Row className="h-100 p-2">
                                        <Col xs={12} className="d-flex justify-content-center align-items-center">
                                            <div
                                                style={{
                                                    width: '80%',
                                                    aspectRatio: '1 / 1', // Ensures a perfect square
                                                    backgroundColor: 'white',
                                                    borderRadius: '50%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                                className='shadow-lg'
                                            >
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/svg/talkToAgent.svg`}
                                                    alt="Photographer Icon"
                                                    style={{ width: '60%', height: '60%' }}
                                                />
                                            </div>
                                        </Col>
                                        <Col
                                            xs={12}
                                            lg={8}
                                            className="d-flex flex-column justify-content-center py-4 py-lg-0"
                                        >
                                            <h1 className="display-4 fw-bold" >
                                            Questions? We're here
                                            </h1>
                                            <p className="mt-3 lead">
                                            Thinking about taking the next step? Knowledgeable agents are available to answer questions, discuss your needs, and help guide your next real estate transaction, even if you decide not to use our service.                                             </p>
                                            <Button
                                                className='btn btn-light mt-3'
                                                style={{
                                                    borderRadius: '10px',
                                                    fontSize: '1rem',
                                                    fontWeight: 'bold',
                                                    margin: "5px"
                                                }}
                                                onClick={() => {functions.triggerPhoneConversion(); window.location.href = `tel:${settings.COMPANYTELPHONE}`}} 
                                            >
                                                Call {settings.COMPANYFRIENDLYPHONE}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
                :


                <div className='bg-white'>
                    <Container>

                        {/* Content Section */}
                        <Row className='mt-4'>
                            <Col>
                                <Card style={{
                                    borderRadius: '20px', backgroundImage: `
                                        linear-gradient(
                                            to right,
                                            rgba(0, 0, 0, 0.8),
                                            rgba(0, 0, 0, 0.6),
                                            rgba(0, 0, 0, 0.6),
                                            rgba(0, 0, 0, 0)
                                        ),
                                        url(${settings.SITEURL}/interior.jpg)
                                        `,
                                    color: 'white',
                                }} className='shadow border-0'>
                                    <Card.Body>
                                        <Row className="h-100 p-5">
                                            <Col
                                                xs={12}
                                                lg={8}
                                                className="d-flex flex-column justify-content-center py-5 py-lg-0"
                                            >
                                                <h1 className="display-4 fw-bold" >
                                                    Questions? We're here
                                                </h1>

                                                <p className="mt-3 lead">
                                                    Thinking about taking the next step? Knowledgeable agents are available to answer questions, discuss your needs, and help guide your next real estate transaction, even if you decide not to use our service. </p>
                                                <Button
                                                    className='btn btn-light mt-5 shadow-lg'
                                                    style={{
                                                        borderRadius: '10px',
                                                        fontSize: '1rem',
                                                        fontWeight: 'bold',
                                                        margin: "5px",
                                                        width: "50%"
                                                    }}
                                                    onClick={() => {functions.triggerPhoneConversion();window.location.href = `tel:${settings.COMPANYTELPHONE}`}} 

                                                >
                                                    Call {settings.COMPANYFRIENDLYPHONE}
                                                </Button>
                                            </Col>
                                            <Col lg={4} className="d-flex justify-content-center align-items-center">
                                                <Col xs={12} className="d-flex justify-content-center align-items-center">
                                                    <div
                                                        style={{
                                                            width: '80%',
                                                            aspectRatio: '1 / 1', // Ensures a perfect square
                                                            backgroundColor: 'white',
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            boxShadow: "box-shadow: rgba(0,0,0,0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px; "
                                                        }}
                                                        className='shadow-lg'
                                                    >
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/svg/talkToAgent.svg`}
                                                            alt="Photographer Icon"
                                                            style={{ width: '80%', height: '80%', filter: 'drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1))' }}

                                                        />
                                                    </div>
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>





            }




        </>
    );
}