import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Chart } from 'react-charts'; // Be sure to npm install react-charts
import SavingsSlider from './SavingsSlider';
import Card from 'react-bootstrap/Card';

export default function SaveMoneySection({ isMobile }) {
    // Simple dataset for a quick bar chart comparing 1.5% vs 3%
    const data = React.useMemo(
        () => [
            {
                label: 'Listing Commission',
                data: [
                    { x: 'Our Service', y: 1.0 },
                    { x: 'Other Agencies', y: 3.0 }
                ],
            },
        ],
        []
    );

    // Minimal chart axes setup
    const axes = React.useMemo(
        () => [
            { primary: true, type: 'ordinal', position: 'bottom' },
            { type: 'linear', position: 'left', format: (d) => `${d}%` }
        ],
        []
    );

    return (
        <div className='bg-white'>
            <Container>
                {/* Heading Section */}
                <Row>
                    <Col sm={12} className='mt-5'>
                        <div className='text-center'>
                            <h5 className='text-secondary'><b>SAVE MONEY SELLING YOUR HOUSE</b></h5>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <div className='text-center'>
                            <h1><b>Save money with low 1% listing fees</b></h1>
                        </div>
                    </Col>
                </Row>

                {/* Content Section */}
                <Row className='mt-4'>
                    <Col>
                        <Card className='shadow border-0'>
                            <Card.Body>
                                <Row>
                                    {/* Left Column */}
                                    <Col xs={12} md={6} className='mb-4'>
                                        <div className=''>
                                            <h3 className='mb-3 '><b>Low 1% agent commission rates save you money</b></h3>
                                            <p>
                                                Many agents can successfully sell real estate, but few do so at a 1% listing rate while still performing the duties traditionally expected.
                                            </p>
                                            <p>
                                                Matthew Kontos Agents is proud to offer comprehensive services at a great value to our clients.
                                            </p>


                                            <Row>

                                                {/* Side-by-side "Clever vs Traditional" illustration */}

                                                {/* Side-by-side "Our Service vs Other Agencies" illustration */}

                                                <Col xs={12} md={6} className="mb-3 mb-md-0">
                                                    <div
                                                        style={{
                                                            backgroundColor: '#f2f2f2',
                                                            padding: '1rem',
                                                            borderRadius: '6px',
                                                            textAlign: 'center',


                                                        }}
                                                    >
                                                        <b>Our Service</b>
                                                        <p className="mb-0">Highly competitive 1% commission & personalized service</p>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={6} className='mb-3'>
                                                    <div
                                                        style={{
                                                            backgroundColor: '#e9ecef',
                                                            padding: '1rem',
                                                            borderRadius: '6px',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <b>Other Agencies</b>
                                                        <p className="mb-0">Typically 2.5-3% & personalized service</p>
                                                    </div>
                                                </Col>


                                                {/* Small bar chart comparing 1.5% vs. 3% */}
                                                <div style={{ width: '90%', height: '200px' }}>
                                                    <Chart data={data} axes={axes} />
                                                </div>

                                            </Row>
                                        </div>
                                    </Col>

                                    {/* Right Column */}
                                    <Col md={6} className="d-flex align-items-center">
                                        <Card className='shadow border-0 w-100'>
                                            <Card.Body>
                                                <SavingsSlider />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}