// 1) In your BuyerGetArea component, remove the inline onClick on the button
// 2) Call handleOptionSelect from your handleFormSubmit

import React, { useEffect, useRef, useState } from "react";
import { Form, FormControl, Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { handleOptionSelect } from "../functions";

export default function BuyerGetArea({ setUserAnswers, navigate, location }) {
  const mapRef = useRef(null);
  const autocompleteRef = useRef(null);
  const markerRef = useRef(null);

  const [map, setMap] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [finalLocation, setFinalLocation] = useState(null);
  const [initialLocation, setInitialLocation] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const propertyLocation = params.get("propertyLocation");
    if (propertyLocation) {
      setInitialLocation(decodeURIComponent(propertyLocation));
    }

    const loadGoogleMapsAPI = async () => {
      if (!window.google) {
        const script = document.createElement("script");
        script.src =
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyCCpHgwvCBKH38lAukfVdOdbO4bNWzgBns&libraries=places";
        script.async = true;
        script.onload = () => initializeMap(propertyLocation);
        document.body.appendChild(script);
      } else {
        initializeMap(propertyLocation);
      }
    };

    loadGoogleMapsAPI();
  }, [location.search]);

  const initializeMap = (propertyLocation) => {
    const mapInstance = new window.google.maps.Map(mapRef.current, {
      center: { lat: 41.8781, lng: -87.6298 },
      zoom: 12,
    });
    setMap(mapInstance);

    const autocomplete = new window.google.maps.places.Autocomplete(
      autocompleteRef.current,
      {
        types: ["geocode"],
        componentRestrictions: { country: "us" },
      }
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        setSelectedLocation(place);
        setFinalLocation({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      }
    });

    if (propertyLocation) {
      geocodeAddress(propertyLocation, mapInstance);
    }
  };

  const geocodeAddress = (address, mapInstance) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === "OK" && results[0].geometry) {
        const location = results[0].geometry.location;
        setFinalLocation({ lat: location.lat(), lng: location.lng() });
        mapInstance.setCenter(location);
        mapInstance.setZoom(14);

        if (!markerRef.current) {
          markerRef.current = new window.google.maps.Marker({
            position: location,
            map: mapInstance,
          });
        } else {
          markerRef.current.setPosition(location);
        }
      } else {
        console.error("Geocode was not successful for the following reason:", status);
      }
    });
  };

  useEffect(() => {
    if (map && finalLocation) {
      map.setCenter(finalLocation);
      if (!markerRef.current) {
        markerRef.current = new window.google.maps.Marker({
          position: finalLocation,
          map: map,
        });
      } else {
        markerRef.current.setPosition(finalLocation);
      }
    }
  }, [map, finalLocation]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log("Submitted location:", finalLocation);

    // Now call your existing function that does the navigation, etc.
    // We are NOT changing the function’s internals, just calling it here:
    handleOptionSelect(navigate, location, setUserAnswers, "MapSubmission", {
      selectedLocation,
      finalLocation,
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <h1>Find Your Dream Property</h1>
      <p>Enter a <b>city or address</b> to get started. You can add more later.</p>

      {/* 
         The Form has an onSubmit handler. The Next button is a type="submit" 
         so it will trigger handleFormSubmit.
      */}
      <Form onSubmit={handleFormSubmit} className="mb-3 d-flex align-items-center">
        <Container>
          <Row>
            <Col xs={12}>
              <FormControl
                type="text"
                placeholder="Select city or address"
                ref={autocompleteRef}
                defaultValue={initialLocation}
                className="me-2"
                style={{ width: "100%" }}
              />
            </Col>

            {finalLocation && (
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                {/* 
                  Remove the onClick so we rely on the Form submission:
                */}
                <Button variant="primary" type="submit">
                  Next
                </Button>
              </div>
            )}
          </Row>
        </Container>
      </Form>

      <div
        ref={mapRef}
        style={{ width: "100%", height: "300px", border: "1px solid #ddd" }}
      />
    </div>
  );
}