import Button from 'react-bootstrap/Button';
import { formButtonStyle } from '../../styles';
import { handleOptionSelect } from '../../functions';

export default function BuyRealEstate7({ setUserAnswers, navigate, location }) {
    return (
        <>
            <h2>Can you estimate your current credit score?</h2>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "CreditScoreQuestion", "740 or higher")
                }
            >
                740 or higher
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "CreditScoreQuestion", "Between 680 and 739")
                }
            >
                Between 680 and 739
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "CreditScoreQuestion", "Between 620 and 679")
                }
            >
                Between 620 and 679
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "CreditScoreQuestion", "Between 580 and 619")
                }
            >
                Between 580 and 619
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "CreditScoreQuestion", "Below 580")
                }
            >
                Below 580
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "CreditScoreQuestion", "Not sure")
                }
            >
                Not sure
            </Button>
        </>
    );
}