import Button from 'react-bootstrap/Button';
import { formButtonStyle } from '../../styles';
import { handleOptionSelect } from '../../functions';

export default function BuyRealEstate6({ setUserAnswers, navigate, location }) {
    return (
        <>
            <h2>What is your timeline for purchasing a property?</h2>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "PurchaseTimelineQuestion", "Immediately")
                }
            >
                Immediately
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "PurchaseTimelineQuestion", "Within the next 3 months")
                }
            >
                Within the next 3 months
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "PurchaseTimelineQuestion", "3 to 6 months")
                }
            >
                3 to 6 months
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "PurchaseTimelineQuestion", "6 to 12 months")
                }
            >
                6 to 12 months
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "PurchaseTimelineQuestion", "More than a year")
                }
            >
                More than a year
            </Button>
        </>
    );
}