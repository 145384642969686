import Button from 'react-bootstrap/Button';
import { formButtonStyle } from '../../styles';
import { handleOptionSelect } from '../../functions';

export default function SellRealEstate7({ setUserAnswers, navigate, location }) {
    return (
        <>
            <h2>What is the estimated value of your home?</h2>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "HomeValueQuestion", "$250K or less")
                }
            >
                $250K or less
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "HomeValueQuestion", "$250K–$500K")
                }
            >
                $250K–$500K
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "HomeValueQuestion", "$500K–$750K")
                }
            >
                $500K–$750K
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "HomeValueQuestion", "$750K–$1M")
                }
            >
                $750K–$1M
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "HomeValueQuestion", "Over $1M")
                }
            >
                Over $1M
            </Button>
        </>
    );
}