import Button from 'react-bootstrap/Button';
import { formButtonStyle } from '../../styles';
import { handleOptionSelect } from '../../functions';

export default function BuyRealEstate8({ setUserAnswers, navigate, location }) {
    return (
        <>
            <h2>Do you have at least $8,000 saved for a down payment on your mortgage?</h2>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "DownPaymentQuestion", "Yes")
                }
            >
                Yes
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "DownPaymentQuestion", "No")
                }
            >
                No
            </Button>
        </>
    );
}