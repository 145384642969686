import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Title from "./Title";
import ProgressiveButton from './ProgressiveButton';
import BuyRealEstateSteps from '../pages/BuyRealEstate/BuyRealEstateSteps';
import HouseLockoutSteps from '../pages/HouseLockout/HouseLockoutSteps';
import RekeyLocksSteps from '../pages/RekeyLocks/RekeyLocksSteps';
import { useNavigate, useLocation } from 'react-router-dom';
import { House } from 'react-bootstrap-icons';
import InstallLocksSteps from '../pages/InstallLocks/InstallLocksSteps';
import LockRepairSteps from '../pages/LockRepair/LockRepairSteps';
import KeyDuplicationSteps from '../pages/KeyDuplication/KeyDuplicationSteps';
import ElectronicLockSteps from '../pages/ElectronicLocks/ElectronicLockSteps';
import VariableService from '../pages/VariableService';
import { Col, Container, Row } from 'react-bootstrap';
import SellRealEstateSteps from '../pages/SellRealEstate/SellRealEstateSteps';
import BuyAndSellSteps from '../pages/BuyAndSell/BuyAndSellSteps';



export default function Step({ isMobile }) {
    const { id } = useParams();
    const [isWorking, setIsWorking] = useState(true);
    const [progress, setProgress] = useState(0);
    const [progressColor, setProgressColor] = useState("info");
    const [progressAnimated, setProgressAnimated] = useState(true);
    const [progressStriped, setProgressStriped] = useState(true);
    const [currentStepName, setCurrentStepName] = useState('');
    const [userAnswers, setUserAnswers] = useState([]);
    const navigate = useNavigate();

    const location = useLocation();


    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [location.pathname]);
    useEffect(() => {
        setIsWorking(true); // Reset state to show the spinner again
        const randomDelay = Math.random() * (0.8 - 0.4) + 0.4; // Random delay between 0.4 and 0.8 seconds
        const timer = setTimeout(() => {
            setIsWorking(false); // Hide the spinner after the delay
        }, randomDelay * 1000);

        return () => clearTimeout(timer); // Clean up the timer on unmount or re-render
    }, [id]); // Dependency on `id` ensures the effect is triggered on re-render

    return (
        <Container>


            <Row className="flex-grow-1 m-0" style={{ flex: '1 1 0px', display: 'flex', flexDirection: 'row', height: 'calc(100vh - 56px)' }}>



                {!isMobile &&

                    <Col
                        md={6}
                        className=" p-0"
                        style={{
                            overflow: 'hidden',
                            height: '100%',
                        }}
                    >
                        <div

                            style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <img
                                //src="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/simple_sale/simple_sale_frame_desktop-e0a3ff56fbd332870c9d98557d75b7956cca07ae6c4ad26190274b974698850f.png"
                                src={`${process.env.PUBLIC_URL}/svg/openHouse.svg`}
                                alt="Static Display"
                                className="img-fluid"
                                style={{

                                    width: '50%',
                                    height: '50%',
                                }}
                            />
                        </div>

                    </Col>
                }



                <Col
                    md={6}
                    className="p-4"
                    style={{
                        flex: '1 1 0px',
                        flexGrow: 1,
                        flexShrink: 1,
                        flexBasis: '0px',
                        overflowY: 'scroll',
                        height: '100%',
                        position: 'relative',
                    }}
                    id={"rightSide"}
                >



                    <div

                        style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <h1>{currentStepName}</h1>
                        <ProgressBar
                            striped={progressStriped}
                            variant={progressColor}
                            animated={progressAnimated}
                            now={progress}
                            style={{
                                width: "90%",
                                //position: "absolute", // Ensures the element is positioned relative to its nearest positioned ancestor.
                                top: "15px",          // Moves the element 15px down from the top of the container.
                                margin: "15px",
                            }}
                        />
                        {isWorking ? (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <Spinner animation="border" size="xl" />
                                <span>Working</span>
                            </div>
                        ) : (
                            <>
                                {id >= 100 && id < 150 && <BuyRealEstateSteps setUserAnswers={setUserAnswers} setProgress={setProgress} setProgressColor={setProgressColor} setProgressAnimated={setProgressAnimated} setProgressStriped={setProgressStriped} originalId={id} setCurrentStepName={setCurrentStepName} navigate={navigate} location={location} />}
                                {id >= 150 && id < 200 && <SellRealEstateSteps setUserAnswers={setUserAnswers} setProgress={setProgress} setProgressColor={setProgressColor} setProgressAnimated={setProgressAnimated} setProgressStriped={setProgressStriped} originalId={id} setCurrentStepName={setCurrentStepName} navigate={navigate} location={location} />}
                                {id >= 200 && id < 250 && <BuyAndSellSteps setUserAnswers={setUserAnswers} setProgress={setProgress} setProgressColor={setProgressColor} setProgressAnimated={setProgressAnimated} setProgressStriped={setProgressStriped} originalId={id} setCurrentStepName={setCurrentStepName} navigate={navigate} location={location} />}





                            </>
                        )}
                    </div>

                </Col>









            </Row>
        </Container>
    );
}