
import Button from 'react-bootstrap/Button';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import GetAddress from '../GetAddress';
import BuyRealEstate1 from './BuyRealEstate1';
import BuyRealEstate2 from './BuyRealEstate2';
import BuyRealEstate3 from './BuyRealEstate3';
import BuyRealEstate4 from './BuyRealEstate4';
import BuyRealEstate5 from './BuyRealEstate5';
import BuyRealEstate6 from './BuyRealEstate6';
import BuyRealEstate7 from './BuyRealEstate7';
import PhoneVerify from '../PhoneVerify';
import BuyRealEstate8 from './BuyRealEstate8';
import BuyerGetArea from '../BuyerGetArea';
import CompleteForm from "../CompleteForm";
//uses 100-150

export default function BuyRealEstateSteps({ setProgress, originalId, setCurrentStepName, setUserAnswers, navigate, location, setProgressColor, setProgressAnimated, setProgressStriped }) {

    const currentStep = originalId - 100 + 1;
    setCurrentStepName("Find your dream property")

    switch (
    currentStep
    ) {
        case 1:
            setProgress(10);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <BuyerGetArea setUserAnswers={setUserAnswers} navigate={navigate} location={location} />

        case 2:
            setProgress(20);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <BuyRealEstate1 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />
        case 3:
            setProgress(40);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <BuyRealEstate2 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />
        case 4:
            setProgress(60);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <BuyRealEstate3 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />
        case 5:
            setProgress(70);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <BuyRealEstate4 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />
        case 6:
            setProgress(80);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <BuyRealEstate5 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />
        case 7:
            setProgress(85);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <BuyRealEstate6 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />
        case 8:
            setProgress(90);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <BuyRealEstate7 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />
        case 9:
            setProgress(95);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <BuyRealEstate8 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />
        case 10: 
            setProgress(100);
            setProgressColor("success");
            setProgressAnimated(false);
            return <CompleteForm section="buy" setUserAnswers={setUserAnswers} navigate={navigate} location={location}/>
    }
    // return <GetAddress />
    return (
        <>


        {/*}
            <Link to={`/step/${parseInt(originalId) + 1}`} style={{ textDecoration: "none" }}>
                <Button>CLO {currentStep} +</Button>
            </Link>



            */}

        </>
    );
}