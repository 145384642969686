import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function OurServiceSection({ isMobile }) {
  return (
    <div className='bg-light'>
      <Container>
        <Row>
          <Col sm={12} className='mt-5'>
            <div className='text-center'>
              <h5 className='text-secondary '><b>HOW WE SELL</b></h5>
            </div>
          </Col>
          <Col sm={12}>
            <div className='text-center'>
              <h1><b>Benefits of a comprehensive listing service</b></h1>
              <p><div>
                Sell fast and get the most value from the sale of your home by using proven methods.
              </div>
                <div>
                  We're here to help you through the process and ensure the smoothest transaction possible!
                </div>
              </p>
            </div>
          </Col>

          {/* SERVICE ITEM 1 */}
                    {/* SERVICE ITEM 2 */}
                    <Col xs={12} lg={4} className="d-flex flex-column align-items-center text-center mb-4">
            <img
              src={`${process.env.PUBLIC_URL}/svg/photographer.svg`}
              alt="Professional Photography"
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'contain',
                filter: 'drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1))'
              }}
            />
            <h5 className="mt-3 mb-0">Professional Photography</h5>
          </Col>

          <Col
            xs={12}
            lg={4}
            className="d-flex flex-column align-items-center text-center mb-4"
          >
            <img
              src={`${process.env.PUBLIC_URL}/svg/marketanalysis.svg`}
              alt="Market Analysis"
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'contain',
                filter: 'drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1))'
              }}
            />
            <h5 className="mt-3 mb-0">Comparative Market Analysis</h5>
          </Col>

          {/* SERVICE ITEM 3 */}
          <Col xs={12} lg={4} className="d-flex flex-column align-items-center text-center mb-4">
            <img
              src={`${process.env.PUBLIC_URL}/svg/openHouse.svg`}
              alt="Open Houses"
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'contain',
                filter: 'drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1))'
              }}
            />
            <h5 className="mt-3 mb-0">Open Houses</h5>
          </Col>

          {/* SERVICE ITEM 4 */}
          <Col xs={12} lg={4} className="d-flex flex-column align-items-center text-center mb-4">
            <img
              src={`${process.env.PUBLIC_URL}/svg/showings.svg`}
              alt="Showings"
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'contain',
                filter: 'drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1))'
              }}
            />
            <h5 className="mt-3 mb-0">Showings On Your Schedule</h5>
          </Col>

          {/* SERVICE ITEM 5 */}
          <Col xs={12} lg={4} className="d-flex flex-column align-items-center text-center mb-4">
            <img
              src={`${process.env.PUBLIC_URL}/svg/repairs.svg`}
              alt="Renovation & Repair Assistance"
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'contain',
                filter: 'drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1))'
              }}
            />
            <h5 className="mt-3 mb-0">Renovation & Repair Assistance</h5>
          </Col>
          <Col xs={12} lg={4} className="d-flex flex-column align-items-center text-center mb-4">
            <img
              src={`${process.env.PUBLIC_URL}/svg/contracts.svg`}
              alt="Renovation & Repair Assistance"
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'contain',
                filter: 'drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1))'
              }}
            />
            <h5 className="mt-3 mb-0">Offer Presentation & Review</h5>
          </Col>
        </Row>
      </Container>
    </div>
  );
}