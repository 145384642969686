import React, { useEffect, useRef, useState } from "react";
import { Form, FormControl, Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { handleOptionSelect } from "../functions";

export default function SellerGetAddress({ setUserAnswers, navigate, location }) {
  const mapRef = useRef(null);          // Reference to the map container
  const autocompleteRef = useRef(null); // Reference to the autocomplete input
  const markerRef = useRef(null);       // We'll store a single marker reference

  const [map, setMap] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [finalLocation, setFinalLocation] = useState(null);

  useEffect(() => {
    const loadGoogleMapsAPI = async () => {
      if (!window.google) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCCpHgwvCBKH38lAukfVdOdbO4bNWzgBns&libraries=places`;
        script.async = true;
        script.onload = initializeMap;
        document.body.appendChild(script);
      } else {
        initializeMap();
      }
    };

    loadGoogleMapsAPI();
  }, []);

  const initializeMap = () => {
    const mapInstance = new window.google.maps.Map(mapRef.current, {
      center: { lat: 41.8781, lng: -87.6298 },
      zoom: 12,
    });
    setMap(mapInstance);

    const autocomplete = new window.google.maps.places.Autocomplete(
      autocompleteRef.current,
      {
        types: ["establishment", "geocode"],
        componentRestrictions: { country: "us" },
      }
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        setSelectedLocation(place);
        setFinalLocation({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      }
    });
  };

  useEffect(() => {
    if (map && finalLocation) {
      map.setCenter(finalLocation);

      if (!markerRef.current) {
        markerRef.current = new window.google.maps.Marker({
          position: finalLocation,
          map: map,
        });
      } else {
        markerRef.current.setPosition(finalLocation);
      }
    }
  }, [map, finalLocation]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log("Submitted location:", finalLocation);
  };

  return (
    <div style={{width: "100%"}}>
      <h1>What is the property address you're interested in selling?</h1>

      <Form onSubmit={handleFormSubmit} className="mb-3 d-flex align-items-center">
        <Container>
          <Row>
            <Col xs={12}>
              <div>
                <FormControl
                  type="text"
                  placeholder="8 E Chestnut, Chicago, IL"
                  ref={autocompleteRef}
                  className="me-2"
                  style={{ width: "100%" }}
                />
              </div>
            </Col>

            <div
              style={{
                width: "10%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              {finalLocation && (
                <Button
                  style={{ marginTop: "10px", width: "200px" }}
                  variant="primary"
                  type="submit"
                  onClick={() => handleOptionSelect(navigate, location, setUserAnswers, "MapSubmission", { selectedLocation, finalLocation })}
                >
                  Next
                </Button>
              )}
            </div>
          </Row>
        </Container>
      </Form>
      <div
        ref={mapRef}
        style={{ width: "100%", height: "300px", border: "1px solid #ddd" }}
      ></div>

    </div>
  );
}
