
import Button from 'react-bootstrap/Button';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import GetAddress from '../GetAddress';
import SellRealEstate1 from './SellRealEstate1';
import SellRealEstate2 from './SellRealEstate2';
import SellRealEstate3 from './SellRealEstate3';
import SellRealEstate4 from './SellRealEstate4';
import SellRealEstate5 from './SellRealEstate5';
import SellRealEstate6 from './SellRealEstate6';
import SellRealEstate7 from './SellRealEstate7';
import PhoneVerify from '../PhoneVerify';
import SellerGetAddress from '../SellerGetAddress';
import CompleteForm from '../CompleteForm';
//uses 100-150

export default function SellRealEstateSteps({ setProgress, originalId, setCurrentStepName, setUserAnswers, navigate, location, setProgressColor, setProgressAnimated, setProgressStriped }) {

    const currentStep = originalId - 150 + 1;
    setCurrentStepName("Sell your home for a 1% listing fee")

    switch (
    currentStep
    ) {
        case 1:
            setProgress(10);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <SellerGetAddress setUserAnswers={setUserAnswers} navigate={navigate} location={location} />
        case 2:
            setProgress(20);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <SellRealEstate1 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />
        case 3:
            setProgress(40);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <SellRealEstate2 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />
        case 4:
            setProgress(60);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <SellRealEstate3 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />
        case 5:
            setProgress(70);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <SellRealEstate4 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />
        case 6:
            setProgress(80);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <SellRealEstate5 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />
        case 7:
            setProgress(85);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <SellRealEstate6 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />
        case 8:
            setProgress(90);
            setProgressColor("primary");
            setProgressAnimated(true);
            return <SellRealEstate7 setUserAnswers={setUserAnswers} navigate={navigate} location={location} />

        case 9:
            setProgress(100);
            setProgressColor("success");
            setProgressAnimated(false);
            return <CompleteForm section="sell" setUserAnswers={setUserAnswers} navigate={navigate} location={location} />

    }
    // return <GetAddress />
    return (
        <>


            {/*}
            <Link to={`/step/${parseInt(originalId) + 1}`} style={{ textDecoration: "none" }}>
                <Button>CLO {currentStep} +</Button>
            </Link>



            */}

        </>
    );
}