import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import { ArrowForward, ArrowDownward } from '@mui/icons-material';
import * as settings from "../settings";
import Tooltip from '@mui/material/Tooltip';

export default function WorkingWithUsSection({ isMobile }) {
    const [activeTab, setActiveTab] = useState('sell');
    const [hoveredArrow, setHoveredArrow] = useState(null);

    const arrowStyle = (index) => ({
        transition: 'transform 0.3s ease-in-out',
        transform: hoveredArrow === index ? 'rotate(360deg)' : 'rotate(0deg)'
    });


    const cardStyle = {
        width: '22rem',
        //minHeight: '360px',
        height: "500px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    };
    const sellCardStyle = {
        width: '22rem',
        //minHeight: '360px',
        height: "550px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    };

    const svgContainerStyle = {
        width: '80%',
        aspectRatio: '1 / 1',
        backgroundColor: 'white',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px',
    };

    return (
        <div className="bg-white">
            <Container>
                {/* Heading Section */}
                <Row>
                    <Col sm={12} className='mt-5'>
                        <div className='text-center'>
                            <h5 className='text-secondary'><b>WORKING WITH US</b></h5>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <div className="text-center">
                            <h1 className="fw-bold display-5">What happens when you work with us</h1>
                        </div>
                    </Col>
                </Row>

                {/* Navigation Tabs */}
                <Row className="mt-4">
                    <Col sm={12}>
                        <Tab.Container activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                            <Nav variant="pills" className="justify-content-center">
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="buy"
                                        className={`px-4 py-2 rounded-pill ${activeTab === 'buy' ? 'bg-primary text-white shadow-sm' : 'text-dark'}`}
                                    >
                                        Buy
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="sell"
                                        className={`px-4 py-2 rounded-pill ${activeTab === 'sell' ? 'bg-primary text-white shadow-sm' : 'text-dark'}`}
                                    >
                                        Sell
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="buySell"
                                        className={`px-4 py-2 rounded-pill ${activeTab === 'buySell' ? 'bg-primary text-white shadow-sm' : 'text-dark'}`}
                                    >
                                        Buy & Sell
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content className="mt-4">
                                {/* Buy Tab Content */}
                                <Tab.Pane eventKey="buy">
                                    <Row className="justify-content-center align-items-center text-center mb-5">
                                        <Col xs={12} md={3} className="d-flex justify-content-center">
                                            <Card className="shadow border-0 p-4" style={cardStyle}>
                                                <Card.Body>
                                                    <div className="d-flex justify-content-center mb-4">
                                                        <div style={svgContainerStyle} className="shadow-lg">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/svg/meetAgent.svg`}
                                                                alt="Find Agent Icon"
                                                                style={{ width: '100%', height: '100%' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Card.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                                        Meet your agent
                                                    </Card.Title>
                                                    <Card.Text className="mt-3" style={{ fontSize: '1rem', color: '#666' }}>
                                                        Get introduced to your agent and tell them about your ideal home.
                                                    </Card.Text>
                                                    <Card.Text className="" style={{ fontSize: '1rem', color: '#666' }}>
                                                        Register for alerts when new properties appear on the market.
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xs={12} md={1} className="d-flex justify-content-center">
                                            {isMobile ? (
                                                <ArrowDownward style={arrowStyle(1)}
                                                    onMouseEnter={() => setHoveredArrow(1)}
                                                    onMouseLeave={() => setHoveredArrow(null)} className="my-3" fontSize="large" color="primary" />
                                            ) : (
                                                <ArrowForward style={arrowStyle(1)}
                                                    onMouseEnter={() => setHoveredArrow(1)}
                                                    onMouseLeave={() => setHoveredArrow(null)} fontSize="large" color="primary" />
                                            )}
                                        </Col>
                                        <Col xs={12} md={3} className="d-flex justify-content-center">
                                            <Card className="shadow border-0 p-4" style={cardStyle}>
                                                <Card.Body>
                                                    <div className="d-flex justify-content-center mb-4">
                                                        <div style={svgContainerStyle} className="shadow-lg">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/svg/buyHouse.svg`}
                                                                alt="Buy House Icon"
                                                                style={{ width: '70%', height: '70%' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Card.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                                        Search for and tour houses
                                                    </Card.Title>
                                                    <Card.Text className="mt-3" style={{ fontSize: '1rem', color: '#666' }}>
                                                        Look for the perfect homes that meet your criteria.
                                                    </Card.Text>
                                                    <Card.Text className="" style={{ fontSize: '1rem', color: '#666' }}>
                                                        View them in person with your agent, all on your schedule.
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xs={12} md={1} className="d-flex justify-content-center">
                                            {isMobile ? (
                                                <ArrowDownward style={arrowStyle(1)}
                                                onMouseEnter={() => setHoveredArrow(1)}
                                                onMouseLeave={() => setHoveredArrow(null)}  className="my-3" fontSize="large" color="primary" />
                                            ) : (
                                                <ArrowForward style={arrowStyle(1)}
                                                onMouseEnter={() => setHoveredArrow(1)}
                                                onMouseLeave={() => setHoveredArrow(null)}  fontSize="large" color="primary" />
                                            )}
                                        </Col>
                                        <Col xs={12} md={3} className="d-flex justify-content-center">
                                            <Card className="shadow border-0 p-4" style={cardStyle}>
                                                <Card.Body>
                                                    <div className="d-flex justify-content-center mb-4">
                                                        <div style={svgContainerStyle} className="shadow-lg">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/svg/workTogether.svg`}
                                                                alt="Work Together Icon"
                                                                style={{ width: '100%', height: '100%' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Card.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                                        Close the deal
                                                    </Card.Title>
                                                    <Card.Text className="mt-3" style={{ fontSize: '1rem', color: '#666' }}>
                                                        Using market data, property inspections, and other resources, make an informed offer.
                                                    </Card.Text>
                                                    <Card.Text className="mt-3" style={{ fontSize: '1rem', color: '#666' }}>
                                                        Get guidance every step of the way and close with confidence.
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="sell">
                                    <Row className="justify-content-center align-items-center text-center mb-5">
                                        <Col xs={12} md={3} className="d-flex justify-content-center">
                                            <Card className="shadow border-0 p-4" style={sellCardStyle}>
                                                <Card.Body>
                                                    <div className="d-flex justify-content-center mb-4">
                                                        <div style={svgContainerStyle} className="shadow-lg">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/svg/homeValue.svg`}
                                                                alt="Find Agent Icon"
                                                                style={{ width: '100%', height: '100%' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Card.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                                        Get the value of your home
                                                    </Card.Title>
                                                    <Card.Text className="mt-3" style={{ fontSize: '1rem', color: '#666' }}>
                                                        Speak with an agent about the details of your property.
                                                    </Card.Text>
                                                    <Card.Text className="" style={{ fontSize: '1rem', color: '#666' }}>
                                                        They will create a report to see what similar houses are selling for and add value for upgrades you may have made.
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xs={12} md={1} className="d-flex justify-content-center">
                                            {isMobile ? (
                                                <ArrowDownward style={arrowStyle(1)}
                                                onMouseEnter={() => setHoveredArrow(1)}
                                                onMouseLeave={() => setHoveredArrow(null)}  className="my-3" fontSize="large" color="primary" />
                                            ) : (
                                                <ArrowForward style={arrowStyle(1)}
                                                onMouseEnter={() => setHoveredArrow(1)}
                                                onMouseLeave={() => setHoveredArrow(null)}  fontSize="large" color="primary" />
                                            )}
                                        </Col>
                                        <Col xs={12} md={3} className="d-flex justify-content-center">
                                            <Card className="shadow border-0 p-4" style={sellCardStyle}>
                                                <Card.Body>
                                                    <div className="d-flex justify-content-center mb-4">
                                                        <div style={svgContainerStyle} className="shadow-lg">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/svg/openHouse2.svg`}
                                                                alt="Buy House Icon"
                                                                style={{ width: '70%', height: '70%' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Card.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                                        Prepare for the sale
                                                    </Card.Title>
                                                    <Card.Text className="mt-3" style={{ fontSize: '1rem', color: '#666' }}>
                                                        Work with your agent to create a sales strategy that meets your needs, timeline, and desired level of involvement.
                                                    </Card.Text>
                                                    <Card.Text className="" style={{ fontSize: '1rem', color: '#666' }}>
                                                        Your agent will handle professional photography, open houses, showings, and more, all on your schedule.
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xs={12} md={1} className="d-flex justify-content-center">
                                            {isMobile ? (
                                                <ArrowDownward style={arrowStyle(1)}
                                                onMouseEnter={() => setHoveredArrow(1)}
                                                onMouseLeave={() => setHoveredArrow(null)}  className="my-3" fontSize="large" color="primary" />
                                            ) : (
                                                <ArrowForward style={arrowStyle(1)}
                                                onMouseEnter={() => setHoveredArrow(1)}
                                                onMouseLeave={() => setHoveredArrow(null)}  fontSize="large" color="primary" />
                                            )}
                                        </Col>
                                        <Col xs={12} md={3} className="d-flex justify-content-center">
                                            <Card className="shadow border-0 p-4" style={sellCardStyle}>
                                                <Card.Body>
                                                    <div className="d-flex justify-content-center mb-4">
                                                        <div style={svgContainerStyle} className="shadow-lg">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/svg/evaluateOffers.svg`}
                                                                alt="Work Together Icon"
                                                                style={{ width: '100%', height: '100%' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Card.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                                        Evaluate offers
                                                    </Card.Title>
                                                    <Card.Text className="mt-3" style={{ fontSize: '1rem', color: '#666' }}>
                                                        Your agent will present offers and explain the terms of the offer.
                                                    </Card.Text>
                                                    <Card.Text className="mt-3" style={{ fontSize: '1rem', color: '#666' }}>
                                                        You will have the opportunity to accept, reject, or counter any offers received.
                                                    </Card.Text>
                                                    <Card.Text className="mt-3" style={{ fontSize: '1rem', color: '#666' }}>
                                                        Once you accept an offer, a closing date is set.                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="buySell">
                                    <Row className="justify-content-center align-items-center text-center mb-5">
                                        <Col xs={12} md={3} className="d-flex justify-content-center">
                                            <Card className="shadow border-0 p-4" style={cardStyle}>
                                                <Card.Body>
                                                    <div className="d-flex justify-content-center mb-4">
                                                        <div style={svgContainerStyle} className="shadow-lg">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/svg/understandOption.svg`}
                                                                alt="Find Agent Icon"
                                                                style={{ width: '100%', height: '100%' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Card.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                                        Understand your options
                                                    </Card.Title>
                                                    <Card.Text className="mt-3" style={{ fontSize: '1rem', color: '#666' }}>
                                                        Buying and selling at the same time requires planning.
                                                    </Card.Text>
                                                    <Card.Text className="" style={{ fontSize: '1rem', color: '#666' }}>
                                                        Your agent will discuss your options so you can decide your best course of action.
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xs={12} md={1} className="d-flex justify-content-center">
                                            {isMobile ? (
                                                <ArrowDownward style={arrowStyle(1)}
                                                onMouseEnter={() => setHoveredArrow(1)}
                                                onMouseLeave={() => setHoveredArrow(null)}  className="my-3" fontSize="large" color="primary" />
                                            ) : (
                                                <ArrowForward style={arrowStyle(1)}
                                                onMouseEnter={() => setHoveredArrow(1)}
                                                onMouseLeave={() => setHoveredArrow(null)}  fontSize="large" color="primary" />
                                            )}
                                        </Col>
                                        <Col xs={12} md={3} className="d-flex justify-content-center">
                                            <Card className="shadow border-0 p-4" style={cardStyle}>
                                                <Card.Body>
                                                    <div className="d-flex justify-content-center mb-4">
                                                        <div style={svgContainerStyle} className="shadow-lg">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/svg/findDreamProperty.svg`}
                                                                alt="Buy House Icon"
                                                                style={{ width: '70%', height: '70%' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Card.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                                        Find your dream property
                                                    </Card.Title>
                                                    <Card.Text className="mt-3" style={{ fontSize: '1rem', color: '#666' }}>
                                                        Work with your agent to find your ideal new home.
                                                    </Card.Text>
                                                    <Card.Text className="" style={{ fontSize: '1rem', color: '#666' }}>
                                                        You will receive our full personalized buying experience with an ultra-responsive agent.
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xs={12} md={1} className="d-flex justify-content-center">
                                            {isMobile ? (
                                                <ArrowDownward style={arrowStyle(1)}
                                                onMouseEnter={() => setHoveredArrow(1)}
                                                onMouseLeave={() => setHoveredArrow(null)}  className="my-3" fontSize="large" color="primary" />
                                            ) : (
                                                <ArrowForward style={arrowStyle(1)}
                                                onMouseEnter={() => setHoveredArrow(1)}
                                                onMouseLeave={() => setHoveredArrow(null)}  fontSize="large" color="primary" />
                                            )}
                                        </Col>
                                        <Col xs={12} md={3} className="d-flex justify-content-center">
                                            <Card className="shadow border-0 p-4" style={cardStyle}>
                                                <Card.Body>
                                                    <div className="d-flex justify-content-center mb-4">
                                                        <div style={svgContainerStyle} className="shadow-lg">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/svg/sellHouseFor1.svg`}
                                                                alt="Work Together Icon"
                                                                style={{ width: '100%', height: '100%' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Card.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                                        Sell your home for a 1% listing fee
                                                    </Card.Title>
                                                    <Card.Text className="mt-3" style={{ fontSize: '1rem', color: '#666' }}>
                                                        Sell your home fast and for a great listing fee.
                                                    </Card.Text>
                                                    <Card.Text className="" style={{ fontSize: '1rem', color: '#666' }}>
                                                        Receive $600 in gift cards to major retailers of your choice to help you move when you sell.<Tooltip title="Combined sale and purchase value must exceed $300,000 to qualify.">**</Tooltip>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}