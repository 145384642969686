import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import * as settings from "../settings";
import * as functions from "../functions";

export default function LandingGeneralCard({navigate}) {
    return (
        <div
            style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Card style={{ width: '22rem', padding: '1.5rem', borderRadius: '20px' }}>
                <Card.Body>
                    <Card.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                        Find out how we can help.
                    </Card.Title>
                    <Card.Text style={{ fontSize: '1rem', color: '#666', marginBottom: '1.5rem' }}>
                        Use our automated tools to find which services suit you best.
                    </Card.Text>
                    <div className="d-grid gap-2">
                        <Button
                            variant="outline-primary"
                            style={{
                                borderRadius: '10px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                margin: "5px"
                            }}
                            onClick={() => (window.location.href = `${process.env.PUBLIC_URL}/step/100`)}
                        >
                            I’m buying
                        </Button>
                        <Button
                            variant="outline-primary"
                            style={{
                                borderRadius: '10px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                margin: "5px"
                            }}
                            onClick={() => (window.location.href = `${process.env.PUBLIC_URL}/step/150`)}
                        >
                            I’m selling
                        </Button>
                        <Button
                            variant="outline-primary"
                            style={{
                                borderRadius: '10px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                margin: "5px"
                            }}
                            onClick={() => (window.location.href = `${process.env.PUBLIC_URL}/step/200`)}
                        >
                            I’d like to buy and sell
                        </Button>
                    </div>

                </Card.Body>
                <Card.Footer style={{ backgroundColor: 'transparent', height: '60px' }}>
                    <div className="d-flex justify-content-center align-items-center mt-2" style={{ height: '100%' }}>
                        <a onClick={() => functions.triggerPhoneConversion()} href={"tel:" + settings.COMPANYTELPHONE} className="text-muted text-decoration-none">
                            <b><LocalPhoneIcon /> Speak With An Agent</b>
                        </a>
                    </div>
                </Card.Footer>
            </Card>
        </div>
    );
}