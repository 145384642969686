import React from 'react';
import Button from 'react-bootstrap/Button';
import { COMPANYFRIENDLYPHONE } from '../settings';
import * as functions from "../functions";

const CallButton = () => {
  const handleCallClick = (e) => {
    // Prevent default action (optional, to allow function to execute first)
    e.preventDefault();
    functions.triggerPhoneConversion();
    // Redirect to the tel link
    window.location.href = `tel:${COMPANYFRIENDLYPHONE}`;
  };


  return (
    <a href={`tel:${COMPANYFRIENDLYPHONE}`} style={{ textDecoration: 'none' }} onClick={handleCallClick}>
      <Button style={{ margin: '10px' }}>Call {COMPANYFRIENDLYPHONE}</Button>
    </a>
  );
};

export default CallButton;