import Button from 'react-bootstrap/Button';
import { formButtonStyle } from '../../styles';
import { handleOptionSelect } from '../../functions';

export default function SellRealEstate2({ setUserAnswers, navigate, location }) {
    return (
        <>
            <h2>In which decade was your home constructed?</h2>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "HomeConstructionYearQuestion", "2010 or later")
                }
            >
                2010 or later
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "HomeConstructionYearQuestion", "2000s")
                }
            >
                2000s
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "HomeConstructionYearQuestion", "1990s")
                }
            >
                1990s
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "HomeConstructionYearQuestion", "1980s")
                }
            >
                1980s
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "HomeConstructionYearQuestion", "1970s")
                }
            >
                1970s
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "HomeConstructionYearQuestion", "Before 1970")
                }
            >
                Before 1970
            </Button>
        </>
    );
}