import Button from 'react-bootstrap/Button';
import { formButtonStyle } from '../../styles';
import { handleOptionSelect } from '../../functions';

export default function SellRealEstate4({ setUserAnswers, navigate, location }) {
    return (
        <>
            <h2>How would you describe the current condition of your property?</h2>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "HomeConditionQuestion", "Move-in ready")
                }
            >
                Move-in ready
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "HomeConditionQuestion", "Needs minor repairs")
                }
            >
                Needs minor repairs
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "HomeConditionQuestion", "Needs major renovations")
                }
            >
                Needs major renovations
            </Button>
            <Button
                style={formButtonStyle}
                variant="outline-primary"
                onClick={() =>
                    handleOptionSelect(navigate, location, setUserAnswers, "HomeConditionQuestion", "Requires demolition")
                }
            >
                Requires demolition
            </Button>
        </>
    );
}