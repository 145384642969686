import React from 'react';
import { verlayTrigger } from 'react-bootstrap';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import CircleImage from '../components/CircleImage';
import { COMPANYFRIENDLYPHONE } from '../settings';
import CallText from '../components/CallText';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LandingGeneralCard from '../components/LandingGeneralCard';
import LandingGeneralMobileCard from '../components/LandingGeneralMobileCard';
import OurServiceSection from '../components/OurServiceSection';
import HowItWorksSection from '../components/HowItWorksSection';
import SaveMoneySection from '../components/SaveMoneySection';
import BuyersSection from '../components/BuyersSection';
import LowerHeroSection from '../components/LowerHeroSection';
import TalkToAgentSection from '../components/TalkToAgentSection';
import WorkingWithUsSection from '../components/WorkingWithUsSection';
import FrequentlyAskedSection from '../components/FrequentlyAskedSection';
import RealEstateToolsSection from '../components/RealEstateToolsSection';
import BottomGeneralCard from '../components/BottomGeneralCard';
import BottomGeneralMobileCard from '../components/BottomGeneralMobileCard';
import StatsBar from '../components/StatsBar';
import { useNavigate, useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import * as settings from "../settings";


// Hook to detect the device type
const useIsMobile = () => {
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

    React.useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

export default function MainPage() {
    const isMobile = useIsMobile();
    const navigate = useNavigate();



    return (
        <>
            {isMobile ? (
                <>
                    <div
                        className="bg-dark text-light"
                        style={{
                            minHeight: '100vh',
                            backgroundImage: `
            linear-gradient(
              to right,
              rgba(0, 0, 0, 0.8),
              rgba(0, 0, 0, 0.6),
              rgba(0, 0, 0, 0.6),
              rgba(0, 0, 0, 0)
            ),
url(${settings.SITEURL}/hero-bg.jpg)          `,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    >
                        <Container fluid className="h-100">
                            <Row className="h-100">
                                <Col
                                    xs={12}
                                    lg={8}
                                    className="d-flex flex-column justify-content-center py-5 py-lg-0"
                                >
                                    <h1 className="display-4 fw-bold" style={{ marginTop: "70px" }}>
                                        Buying & selling at 1% is easier than ever
                                    </h1>
                                    <h2 className="mt-3 fs-4">
                                        Licensed, vetted, and qualified real estate agents for a fraction of the price.
                                    </h2>
                                    <h2 className="mt-3 fs-4">
                                        Saving money has never been this simple!                                    </h2>
                                </Col>
                                <Col
                                    xs={12}
                                    lg={4}
                                    className="d-flex flex-column justify-content-center align-items-center"
                                >
                                    <LandingGeneralMobileCard />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className=" shadow-lg d-flex flex-column justify-content-center text-center  py-lg-0 bg-secondary" style={{ height: "75px", width: "100vw", }}>
                        <h4 className="text-white">Save <b>$6,500</b> when you list for 1%<Tooltip className="" title="Based on a listing commission of 1% vs other commissions at 3% for a $325,000 sale." arrow>*</Tooltip></h4>
                    </div>
                </>
            ) : (
                <>
                    <div
                        className="bg-dark text-light"
                        style={{
                            minHeight: '100vh',
                            backgroundImage: `
              linear-gradient(
                to right,
                rgba(0, 0, 0, 0.8),
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0)
              ),
              url(${settings.SITEURL}/hero-bg.jpg)
            `,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    >
                        <Container fluid className="h-100">
                            <Row className="h-100">
                                <Col
                                    xs={12}
                                    lg={8}
                                    className="d-flex flex-column justify-content-center py-5 py-lg-0"
                                    style={{ padding: "100px" }}
                                >
                                    <h1 className="display-4 fw-bold sm-mt-5">
                                        Buying & selling at 1% is easier than ever
                                    </h1>
                                    <h2 className="mt-3 fs-4">
                                        Licensed, vetted, and qualified real estate agents for a fraction of the price.
                                    </h2>
                                    <h2 className="mt-3 fs-4">
                                        Saving money has never been this simple!                                    </h2>
                                </Col>
                                <Col
                                    xs={12}
                                    lg={4}
                                    className="d-flex flex-column justify-content-center align-items-center"
                                >
                                    <LandingGeneralCard navigate={navigate} />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="d-flex flex-column justify-content-center text-center py-5 py-lg-0 bg-secondary" style={{ height: "60px", width: "100vw", }}>
                        <h4 className="text-white">Save <b>$6,500</b> when you list for 1%<Tooltip className="" title="Based on a listing commission of 1% vs other commissions at 3% for a $325,000 sale." arrow><b>*</b></Tooltip></h4>
                    </div>
                </>
            )}
            <div className="p5" style={{ marginTop: "30px", marginBottom: "100px" }}>


                <HowItWorksSection />
            </div>
            <div className="p5" style={{ marginTop: "30px" }}>

                <OurServiceSection isMobile={isMobile} />
            </div>
            <div className="p5" style={{ marginTop: "30px" }}>

                <SaveMoneySection isMobile={isMobile} />
            </div>
            <div className="p5" style={{ marginTop: "30px" }}>

                <BuyersSection isMobile={isMobile} />
            </div>




            <div className="p5" >
                <LowerHeroSection navigate={navigate} />
            </div>            <div className="p5" style={{ marginTop: "30px" }}>
                <WorkingWithUsSection isMobile={isMobile} />
            </div>


            <div
                className="p5"
                style={{
                    marginTop: isMobile ? "50px" : "100px",
                    marginBottom: isMobile ? "100px" : "100px",
                }}
            >
                <TalkToAgentSection isMobile={isMobile} />
            </div>

            {/*}
            <div className="p5" style={{ marginTop: "100px" }}>
                <RealEstateToolsSection isMobile={isMobile} />
            </div>
            */}

            <div className="p5">
                <FrequentlyAskedSection isMobile={isMobile} />
            </div>
            <div className="" >


                {isMobile ?


                    <BottomGeneralMobileCard navigate={navigate} />
                    :



                    <div className='bg-light p-5'>

                        <BottomGeneralCard navigate={navigate} />

                    </div>




                }


            </div>


            <div>
                <StatsBar />
            </div>




        </>
    );
}