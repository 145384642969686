import React, { useState, useRef, useEffect } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import * as settings from "../settings";

const GoogleMapAddressInput = () => {
  const [address, setAddress] = useState("");
  const autoCompleteRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const loadGoogleMapsAPI = async () => {
      if (!window.google) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCCpHgwvCBKH38lAukfVdOdbO4bNWzgBns&libraries=places`;
        script.async = true;
        script.onload = initializeAutocomplete;
        document.body.appendChild(script);
      } else {
        initializeAutocomplete();
      }
    };

    loadGoogleMapsAPI();
  }, []);

  const initializeAutocomplete = () => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, {
      types: ["geocode"],
    });

    autoCompleteRef.current.addListener("place_changed", () => {
      const place = autoCompleteRef.current.getPlace();
      if (place && place.formatted_address) {
        setAddress(place.formatted_address);
      }
    });
  };

  const handleRedirect = () => {
    if (address.trim()) {
      const encodedAddress = encodeURIComponent(address);
      window.location.href = `${settings.BUYSTEPURL}/?propertyLocation=${encodedAddress}`;
    }
  };

  return (
    <InputGroup className="mb-3">
      <Form.Control
        type="text"
        placeholder="Enter an address"
        ref={inputRef}
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        className="border-primary"
      />
      <Button variant="primary" onClick={handleRedirect} disabled={!address.trim()}>
        <SearchIcon />
      </Button>
    </InputGroup>
  );
};

export default GoogleMapAddressInput;