import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Accordion, Card } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as settings from "../settings";

export default function FrequentlyAskedSection({ isMobile }) {
    return (
        <section id="faq" className= {isMobile ? "bg-light " : "bg-light p-4"} >
            <Container>
                <Row className="mb-5">
                    <Col sm={12} className="mt-5">
                        <div className="text-center">
                            <h5 className="text-secondary"><b>COMMON QUESTIONS</b></h5>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <div className="text-center">
                            <h1 className="fw-bold display-5">Frequently Asked Questions</h1>
                        </div>
                    </Col>
                </Row>
                <Accordion defaultActiveKey="0" className="mb-5">

                    {/* 1 */}
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>What is the 1% listing fee?</Accordion.Header>
                        <Accordion.Body>
                            Our 1% listing fee is a reduced commission rate for sellers. Instead of paying
                            the traditional 2.5–3% listing agent commission, you only pay 1% when your home sells.
                            We still provide all the services you’d expect—professional photography,
                            showings, open houses, offer negotiation, and more—at a fraction of the cost.
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* 2 */}
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Are there any hidden fees or extra costs?</Accordion.Header>
                        <Accordion.Body>
                            No. Our 1% listing fee is straightforward, and there are no hidden fees for
                            our standard services. However, keep in mind that the buyer’s agent commission
                            (usually 2–3%) is separate and typically paid by the seller.
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* 3 */}
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>How do you offer full service at such a low rate?</Accordion.Header>
                        <Accordion.Body>
                            We leverage modern tools, efficient processes, and a network of vetted professionals
                            to keep our overhead low. This allows us to pass those savings on to you, without
                            sacrificing the quality or scope of service.
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* 4 */}
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Do I really get $600 in gift cards if I buy and sell with you?</Accordion.Header>
                        <Accordion.Body>
                            Yes! When you buy and sell with us at the same time, and the combined transaction
                            value exceeds $300,000, you’re eligible for a $600 gift card to a major retailer
                            of your choice. It’s our way of saying “thank you” for choosing us for both
                            transactions.
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* 5 */}
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>How do I request a showing or view private listings?</Accordion.Header>
                        <Accordion.Body>
                            Simply contact us with the property details you’re interested in. Our buyers’
                            agents are available 24/7 and can arrange same-day showings whenever possible
                            (subject to approval from the seller). We also provide access to private MLS
                            listings and property disclosure reports not publicly available.
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* 6 */}
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>What if I only want to buy (or only want to sell)?</Accordion.Header>
                        <Accordion.Body>
                            That’s perfectly fine! We offer the same personalized level of service whether
                            you are just buying, just selling, or doing both. You don’t need to combine
                            transactions to benefit from our ultra-responsive support and modern approach.
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* 7 */}
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>Do you assist with mortgage lenders and financing?</Accordion.Header>
                        <Accordion.Body>
                            Yes, we maintain partnerships with top agents, lenders, and other real estate
                            professionals. We’re happy to recommend mortgage lenders, inspectors, and
                            other service providers you may need throughout your transaction.
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* 8 */}
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>What is included with your professional photography service?</Accordion.Header>
                        <Accordion.Body>
                            We arrange for a professional photographer to capture high-quality images of
                            your home. These photos will be used in your MLS listing, marketing materials,
                            and online listings to ensure your property stands out and attracts potential buyers.
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* 9 */}
                    <Accordion.Item eventKey="8">
                        <Accordion.Header>Can you help with home repairs or renovations before listing?</Accordion.Header>
                        <Accordion.Body>
                            Absolutely. We can connect you with trusted contractors who specialize in home
                            repairs and renovations. This can help increase the appeal (and often the value)
                            of your home before going on the market.
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* 10 */}
                    <Accordion.Item eventKey="9">
                        <Accordion.Header>How does the closing process work once I accept an offer?</Accordion.Header>
                        <Accordion.Body>
                            Once you accept an offer, our team will guide you step-by-step through the closing
                            process, including inspection, appraisal, document preparation, and final walkthrough.
                            Our goal is to ensure a smooth, stress-free experience until all paperwork is signed
                            and the transaction is officially complete.
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* 11 */}
                    <Accordion.Item eventKey="10">
                        <Accordion.Header>Can I schedule a consultation without any obligation?</Accordion.Header>
                        <Accordion.Body>
                            Yes. We offer a no-pressure consultation where you can talk directly with an agent,
                            discuss your specific needs, and learn more about our services. You’re free to decide
                            whether to move forward afterward—no strings attached.
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* 12 */}
                    <Accordion.Item eventKey="11">
                        <Accordion.Header>How do I get started?</Accordion.Header>
                        <Accordion.Body>
                            You can begin by filling out our quick online form, or give us a call at {settings.COMPANYFRIENDLYPHONE}.
                            We’ll learn about your situation, answer any questions, and help you decide the best
                            path for buying or selling your property.
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
            </Container>
        </section>
    );
}